import React, {useEffect} from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {baseUrl, capitalizeMessage, getToken} from "../../Utils/Constent";
import {Frame} from "@shopify/polaris";

const Public = () => {
    // const location = useLocation();
    //
    // const checkPath = location.pathname || '';
    // useEffect(() => {
    //     const title = checkPath?.split('/')?.[1];
    //     document.title = `WebForm ${title ? `| ${capitalizeMessage(title)}` : ''}`;
    // }, [checkPath]);
    return getToken() ? <Navigate to={`${baseUrl}/dashboard`}/> : <Frame><Outlet/></Frame>
};

export default Public;