import React, {Fragment, useEffect, useState} from 'react';
import confetti from "../../Img/confetti.gif";
import {BlockStack, Box, Button, InlineStack, Text, Thumbnail} from "@shopify/polaris";
import {Icons} from "../../Utils/Icons";
import Instagram from "../../Img/instagram.png";
import LinkedIn from "../../Img/share-linkedin.png";
import X from "../../Img/share-x.png";
import Facebook from "../../Img/share-facebook.png";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {baseUrl} from "../../Utils/Constent";

const ThankYouBoarding = ({onStep, onWorkSpaceCreate}) => {
    const navigate = useNavigate();
    const planDetails = useSelector((state) => state.planDetails);
    const [isConfetti, setIsConfetti] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsConfetti(false)
        },4000)
    },[]);

    const onWindowOpen = (url) => {
        window.open(`${url}`, "_blank")
    }

    const shareArray = [
        {
            img: Instagram,
            alt: "Instagram",
            onClick : () => onWindowOpen(`https://www.instagram.com`),
        },
        {
            img: LinkedIn,
            alt: "LinkedIn",
            onClick : () => onWindowOpen(`https://www.linkedin.com/company/webform-io/`),
        },
        {
            img: X,
            alt: "X",
            onClick : () => onWindowOpen(`https://x.com/Webform_io`),
        },
        {
            img: Facebook,
            alt: "Facebook",
            onClick : () => onWindowOpen(`https://www.facebook.com/webform/`),
        },
    ]

    return (
        <Fragment>
                { isConfetti && Array.from(Array(3)).map((_, r) => <img  key={r} src={confetti} className={`confetti confetti${r+1}`}/>)}

                <BlockStack as={"span"} gap={"600"} align={"center"} inlineAlign={"center"}>
                    <InlineStack align={"center"} as={"span"} gap={"100"} wrap={false}>
                        <Text as={"span"}>{Icons.onThankYou}</Text>
                        <Text variant={"heading2xl"}>Thank You for Signing Up!</Text>
                    </InlineStack>

                    <BlockStack as={"span"} gap={"150"}>
                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                            We’re excited to have you on board.
                        </Text>
                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                            Your journey with Webform starts now! Here’s what you can do next:
                        </Text>
                    </BlockStack>

                    <Box paddingBlock={"200"}>
                        <BlockStack as={"span"} gap={"150"} inlineAlign={"start"}>
                            <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                <Button variant={"monochromePlain"} onClick={onWorkSpaceCreate}><span className={"text-success"}><span className={"d-next"}>{Icons.next}</span> Create your first form</span></Button> and
                                start gathering responses.
                            </Text>
                            <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                <Button variant={"monochromePlain"}> <span className={"text-success"}><span
                                                    className={"d-next"}>{Icons.next}</span> Explore our templates</span></Button> to
                                find the perfect fit for your needs.
                            </Text>

                            {planDetails.planType == '0' &&
                                <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                    <Button variant={"monochromePlain"} onClick={() => navigate(`${baseUrl}/plan`)}>  <span className={"text-success"}><span
                                        className={"d-next"}>{Icons.next}</span> Upgrade your plan</span></Button> to
                                    unlock unlimited features and workspaces.
                                </Text>
                            }
                        </BlockStack>
                    </Box>

                    <BlockStack as={"span"} gap={"150"}>
                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                            Need help? Check out our help center or contact our 24/7 support team.
                        </Text>
                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                            Follow us on social media for the latest updates and tips!
                        </Text>
                    </BlockStack>

                    <Box paddingBlockEnd={"100"} paddingBlockStart={"200"}>
                        <InlineStack gap={"300"}>
                            {
                                shareArray.map((x, i) => {
                                    return (
                                        <Button key={i} variant={"monochromePlain"} onClick={x.onClick}>
                                            <span title={x.alt}><Thumbnail source={x.img} size="small" alt={x.alt}/></span>
                                        </Button>
                                    )
                                })
                            }
                        </InlineStack>
                    </Box>

                    <Box paddingBlockEnd={"150"}>
                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>Happy form building!</Text>
                    </Box>

                    <InlineStack gap={"400"}>
                        <Button variant={"primary"} size={"large"} onClick={() => onStep(4, "create")}>Create First Form</Button>
                        <Button variant={"primary"} size={"large"} onClick={() => onStep(4, 'dashboard')}>&nbsp; &nbsp; &nbsp; Dashboard &nbsp; &nbsp; &nbsp;</Button>
                    </InlineStack>
                </BlockStack>
        </Fragment>
    );
};

export default ThankYouBoarding;