import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {Card, Page, Layout, DropZone, InlineStack, Thumbnail, BlockStack, Button, TextField, Text, Checkbox, Badge, Box, Collapsible, InlineGrid, Divider,
    Bleed, Modal,} from "@shopify/polaris";
import {apiService, baseUrl, getLSUserDetails, googleClientId, imagePath, isEmpty, removeDetails} from "../../Utils/Constent";
import {CheckIcon, DeleteIcon, EditIcon, NoteIcon} from "@shopify/polaris-icons";
import {commonParagraph} from "../../Utils/Loader";
import {setUserDetails} from "../../Redux/Action/Action";
import {useDispatch, useSelector} from "react-redux";
import PlanBadge from "../Common/PlanBadge";
import {Icons} from "../../Utils/Icons";
import {loadAuth2, loadGapiInsideDOM} from "gapi-script";
import CopyCode from "../Common/CopyCode";
import {useNavigate} from "react-router-dom";
import {ToastContext} from "../Common/ToastProvider";
import PasswordFactory from "./Modals/PasswordFactory";
import DeleteAccount from "./Modals/DeleteAccount";
import AccountInformation from "./Modals/AccountInformation";
import TwoFactor from "./Modals/TwoFactor";

const initialState = {
    branding: "",
    email: "",
    enabled2fa: "0",
    firstName: "",
    id: '',
    isGoogleConnect: "",
    isPasswordCreated: "",
    lastName: "",
    loginType: 0,
    profileImage: "",
    secrets2fa: "",
};

const Settings = () => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);

    const navigate = useNavigate();

    const [profileDetails, setProfileDetails] = useState(initialState);
    const [oldProfileDetails, setOldProfileDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [loading, setLoading] = useState('');
    const [isConfirmUpdate, setIsConfirmUpdate] = useState(false);
    const [authPassword, setAuthPassword] = useState('');
    const [authPasswordError, setAuthPasswordError] = useState('');
    const [modalType, setModalType] = useState('');
    const [isShowPaas, setIsShowPaas] = useState(false);
    const [isOpenCode, setIsOpenCode] = useState(false);
    const [gapi, setGapi] = useState(null);
    const [TFADetails, setTFADetails] = useState({qrCode: "", secrets2fa: ""});
    const [isUploadProfile, setIsUploadProfile] = useState(false);
    const [tempProfile, setTempProfile] = useState('');
    const [isRemoveProfile, setIsRemoveProfile] = useState(0);

    const [isPlanLoading, setIsPlanLoading] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);

    const planDetails = useSelector((state) => state.planDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        const loadGapi = async () => {
            const newGapi = await loadGapiInsideDOM();
            setGapi(newGapi);
        }
        loadGapi();
    }, []);

    useEffect(()=> {
        const getProfile = async () => {
            setIsLoading(true)
            const response = await apiService.getProfile();
            if (response.success === true) {
                setIsLoading(false)
                setProfileDetails(response.data)
                setOldProfileDetails(response.data)
                dispatch(setUserDetails(response?.data));
                let user = { ...response.data };
                localStorage.setItem("user-details", JSON.stringify(user));
                setTFADetails({qrCode: response.data.qrCode, secrets2fa: response.data.secrets2fa})
            } else {
                setIsLoading(false)
            }
        }
        getProfile()
    },[])

    const handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) => {
        setIsImage(true)
        setProfileDetails({...profileDetails, profileImage: acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : '' })
        // setTempProfile(acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : '')
    }

    const formValidate = (name, value) => {
        switch (name) {
            case "firstName":
                if (!value.trim()) {
                    return "First name is required.";
                } else {
                    return "";
                }
            case "lastName":
                if (!value.trim()) {
                    return "Last name is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
        setProfileDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((formError) => ({
            ...formError,
            [name]: formValidate(name, value),
        }));
    }, [profileDetails]);

    const handleUpdate = async () => {
        let errorData = {};
        Object.keys(profileDetails).map((x) => {
            let error = formValidate(x, profileDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setLoading('updateProfile');

        const payload = new FormData();
        payload.append('firstName', profileDetails.firstName);
        payload.append('lastName', profileDetails.lastName);
        payload.append('profileImage', isImage ? profileDetails?.profileImage : '');
        if(planDetails.planType == 1){
            payload.append('branding', profileDetails?.branding);
        }
        const response = await apiService.updateProfile(payload);
        if (response.success === true) {
            setLoading('');
            setIsImage(false)
            dispatch(setUserDetails(response?.data));
            const user = {...getLSUserDetails(), ...response.data,};
            localStorage.setItem("user-details", JSON.stringify(user));
            dispatch(setUserDetails(user));
            setMessage("Profile changes saved! 👍");
            setActive(true);
            setProfileDetails({...profileDetails});
            setOldProfileDetails({...profileDetails});
            setIsError(false)
            setIsConfirmUpdate(false)
        } else {
            setLoading('');
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    };

    const validImageTypes = ['jpeg, png, jpg, webp'];

    const fileUpload = !profileDetails.profileImage && <DropZone.FileUpload />;

    const uploadedFile = profileDetails.profileImage && (
            <Thumbnail
                size="small"
                alt={profileDetails.profileImage.name || 'profile'}
                source={
                    validImageTypes.includes(profileDetails.profileImage.type)
                        ? window.URL.createObjectURL(profileDetails.profileImage)
                        : NoteIcon
                }
            />
    );

    const onCancelChange = () => {
        setIsConfirmUpdate(false)
        setProfileDetails({...oldProfileDetails})
    }

    const onModalHit = async (loader, type, currentUser = '') => {
        if (authPassword.trim() === '' && profileDetails.loginType == 0) {
            setAuthPasswordError('Password is required.')
            return
        }
        setLoading(loader);
        let payload;
        if (profileDetails.loginType == 0) {
            payload = {password: authPassword}
        } else {
            let googleId = currentUser.getBasicProfile().getId();
            payload = {googleId: googleId}
        }

        let response;
        if (type === '2FAE') {
            response = await apiService.enable2FA(payload);
        } else if (type === '2FAD') {
            response = await apiService.disable2FA(payload);
        } else if (type === 'DelAcc') {
            response = await apiService.deleteAccount(payload);
        }

        if (response.success === true) {
            setLoading('');
            setActive(true);
            setIsError(false)
            if (type === '2FAE') {
                setProfileDetails({...profileDetails, enabled2fa: '1'});
                setTFADetails(response?.data)
                setMessage('Two-factor authentication is now enabled. ✅');
            } else if (type === '2FAD') {
                setProfileDetails({...profileDetails, enabled2fa: '0'});
                setTFADetails({qrCode: "", secrets2fa: ""})
                setMessage('Two-factor authentication is now disabled. 🚫');
            } else if (type === 'DelAcc') {
                removeDetails()
                if (gapi && gapi.auth2) {
                    const auth2 = gapi.auth2.getAuthInstance();
                    if (auth2) {
                        auth2.signOut().then(() => {
                            console.log('%cUser signed out', 'color: red; background-color: black; padding: 4px;');
                        });
                    }
                }
                setMessage('Account deleted 😢');
                navigate(`${baseUrl}/login`);
            }
            onCancelModal()
        } else {
            setLoading('');
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onCancelModal = () => {
        setAuthPassword('')
        setModalType('')
        setAuthPasswordError('')
        setIsShowPaas(false)
    }

    const onChangeAuth = (value) => {
        setAuthPassword(value)
        setAuthPasswordError('')
    }

    const handleGoogle = async (type = '') => {
        try {
            const auth2 = await loadAuth2(gapi, googleClientId, "profile");
            const googleUser = await auth2.signIn();
            if (profileDetails.loginType == 1 && type === 'DelAcc') {
                await onModalHit('DelAcc', 'DelAcc', googleUser);
            } else {
                await onModalHit('auth', profileDetails.enabled2fa == 1 ? '2FAD' : '2FAE', googleUser);
            }
        } catch (error) {
            console.error('Google sign-in error:', error);
        }
    };

    const onRemoveProfile = () => {
        setIsImage(false)
        // setProfileDetails({...profileDetails, profileImage: '' })
        if(profile){
            setTempProfile('')
        }
    }

    const onUpdateProfile = () => {
        setIsImage(true)
        setProfileDetails({...profileDetails, profileImage: tempProfile})
        setIsUploadProfile(false)
    }

    const onClose = () => {
        setTempProfile('')
        setIsUploadProfile(false)
    }

    const profile = profileDetails?.profileImage?.name ? URL.createObjectURL(profileDetails?.profileImage) :
        profileDetails?.profileImage?.includes('googleusercontent.com') ? profileDetails?.profileImage : `${imagePath}${profileDetails?.profileImage}`;

    const connectGoogle = async (loader) => {
        let payload;
        try {
            const auth2 = await loadAuth2(gapi, googleClientId, "profile");
            const googleUser = await auth2.signIn();
            const getEmail = googleUser.getBasicProfile().getEmail();
            const googleId = googleUser.getBasicProfile().getId()
            payload = {
                email: getEmail,
                googleId: profileDetails?.isGoogleConnect ? "" : googleId
            }
            setLoading(loader);
        } catch (error) {
            setActive(true);
            setIsError(true);
            setMessage(error);
            console.error('Google sign-in error:', error);
            return
        }

        const response = await apiService.connectGoogle(payload);
        if (response.success === true) {
            setLoading('');
            dispatch(setUserDetails(response?.data));
            const user = {...getLSUserDetails(), ...response.data,};
            localStorage.setItem("user-details", JSON.stringify(user));
            dispatch(setUserDetails(user));
            setActive(true);
            if(profileDetails?.isGoogleConnect){
                setMessage("Your account has been disconnected from Google!\n");
            } else {
                setMessage("You’re all set! Your account is now connected to Google! 🎉");
            }
            setProfileDetails({...response?.data});
            setOldProfileDetails({...response?.data});
            setIsError(false)
        } else {
            setLoading('');
            setActive(true);
            setIsError(true);
            setMessage(response?.error?.message);
        }
    }

    const managePlan = async () => {
        setIsPlanLoading(true)
        const response = await apiService.acceptPlan({planInterval: '1'})
        if (response.success) {
            setIsPlanLoading(false)
            window.open(response.data, "_self");
        } else {
            setIsPlanLoading(false)
        }
    }

    return (
        <Fragment>
            <Fragment>
                {isConfirmUpdate && <AccountInformation {...{isConfirmUpdate, onCancelChange, handleUpdate, loading,}}/>}

                {
                    modalType === 'authPass2FA' &&
                    <TwoFactor {...{
                        modalType,
                        onCancelModal,
                        onModalHit,
                        loading,
                        authPassword,
                        authPasswordError,
                        isShowPaas,
                        onChangeAuth,
                        setIsShowPaas,
                        profileDetails
                    }}/>
                }

                {
                    modalType === 'DeleteAccount' &&
                    <DeleteAccount {...{
                        modalType,
                        onCancelModal,
                        profileDetails,
                        loading,
                        authPassword,
                        authPasswordError,
                        isShowPaas,
                        onChangeAuth,
                        setIsShowPaas,
                        handleGoogle,
                        onModalHit
                    }}/>
                }
            </Fragment>

            <Fragment>
                {/*<Modal size={"small"}*/}
                {/*    open={isUploadProfile}*/}
                {/*    onClose={onClose}*/}
                {/*    title="Upload Photo"*/}
                {/*>*/}
                {/*    <Modal.Section>*/}
                {/*        <BlockStack align={"center"} inlineAlign={"center"} gap={"500"}>*/}
                {/*            <div className={"profile-thumbnail profile-thumbnail-inner "}>*/}
                {/*                <div className={"edit-profile-in"}>*/}
                {/*                    <DropZone accept={'image/*'} allowMultiple={false} onDrop={handleDropZoneDrop}>*/}
                {/*                        {uploadedFile}*/}
                {/*                        {fileUpload}*/}
                {/*                    </DropZone>*/}
                {/*                    {tempProfile?.name ? null : <label className={"label-btn"}>Choose File</label> }*/}
                {/*                </div>*/}
                {/*                {tempProfile?.name ? <img alt={"avtar"} src={URL.createObjectURL(tempProfile)}/> :*/}
                {/*                    isEmpty(profileDetails?.profileImage) || isEmpty(profileDetails?.profileImage) ?*/}
                {/*                    <span>{profileDetails?.firstName[0]?.toUpperCase()}</span> :*/}
                {/*                    <img*/}
                {/*                        alt={profileDetails?.firstName[0]?.toUpperCase() + profileDetails?.lastName[0]?.toUpperCase()}*/}
                {/*                        src={profile}/>}*/}
                {/*            </div>*/}
                {/*            <InlineStack gap={"300"}>*/}
                {/*                <Button size={"large"} icon={DeleteIcon} tone={"critical"} onClick={onRemoveProfile} disabled={(profileDetails?.profileImage === '' || profileDetails?.profileImage === null)}/>*/}
                {/*                <Button size={"large"} icon={CheckIcon} tone={"success"} onClick={onUpdateProfile} disabled={tempProfile === ''}/>*/}
                {/*            </InlineStack>*/}
                {/*        </BlockStack>*/}
                {/*    </Modal.Section>*/}
                {/*</Modal>*/}
            </Fragment>

            <PasswordFactory {...{isChangePassword, setIsChangePassword, profileDetails, setProfileDetails, setOldProfileDetails}}/>

            <Page title="Settings">
                <Layout>
                    <Layout.Section>
                        <Card>
                            <BlockStack as={"span"} gap={"500"}>
                                <Text variant={"headingMd"}>My Account</Text>
                                {isLoading ? commonParagraph(2) :
                                    <BlockStack as={"span"} gap={"400"}>
                                        <BlockStack>
                                            {/*<div className="Polaris-Labelled__LabelWrapper">*/}
                                            {/*    <div className="Polaris-Label">*/}
                                            {/*        <label className="Polaris-Label__Text">Profile</label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className={"profile-thumbnail"}>
                                                <div className={"edit-profile"} onClick={() => setIsUploadProfile(true)}>
                                                    <DropZone accept={'.jpeg, .png, .jpg, .webp'} allowMultiple={false} onDrop={handleDropZoneDrop}>
                                                        {uploadedFile}
                                                        {fileUpload}
                                                    </DropZone>
                                                    <Button variant={"plain"} icon={EditIcon} />
                                                </div>
                                                {isEmpty(profileDetails?.profileImage) ? <span>{(profileDetails?.firstName[0]?.toUpperCase() || "") + (profileDetails?.lastName[0]?.toUpperCase() || '')}</span> :
                                                    <img src={profile} alt={profileDetails?.firstName[0]?.toUpperCase() + profileDetails?.lastName[0]?.toUpperCase()}/>
                                                }
                                            </div>
                                        </BlockStack>

                                        <InlineGrid gap="400" columns={{xs: 1, sm: 2, md: 2, lg: 2, xl: 2,}}>
                                            <TextField
                                                label="First name"
                                                placeholder={"Enter first name"}
                                                value={profileDetails.firstName}
                                                error={formError.firstName}
                                                onChange={(value) => onHandleChange("firstName", value)}
                                            />

                                            <TextField
                                                label="Last name"
                                                placeholder={"Enter last name"}
                                                value={profileDetails.lastName}
                                                error={formError.lastName}
                                                onChange={(value) => onHandleChange("lastName", value)}
                                            />

                                            <TextField
                                                label="Email" type={"email"}
                                                placeholder={"Enter email"} disabled
                                                value={profileDetails.email}
                                            />

                                            <TextField
                                                label="Password" type={"password"} value={'*********'} placeholder={"Enter password"}
                                                disabled suffix={<Bleed marginInlineEnd={"200"}>
                                                <Button variant={"tertiary"} onClick={()=> setIsChangePassword(true)}>{(profileDetails?.isGoogleConnect && !profileDetails?.isPasswordCreated) ? "Set" : "Change"} Password</Button>
                                                </Bleed>}
                                            />
                                        </InlineGrid>

                                        <Checkbox
                                            label={<Fragment>Hide WebForm Branding {planDetails.planType == 0 ? <PlanBadge/> :""}</Fragment>}
                                            checked={profileDetails?.branding == 1} disabled={planDetails.planType == 0}
                                            onChange={planDetails.planType == 0 ? null : (checked) => onHandleChange("branding", checked ? 1 : 0)}
                                        />

                                        <InlineStack align={"end"} gap={"100"}>
                                        {/*<InlineStack align={planDetails.planType == 1 ? "space-between" : "end"} gap={"100"}>*/}
                                        {/*    {*/}
                                        {/*        planDetails.planType == 1 &&*/}
                                        {/*        <Button variant={"plain"} onClick={managePlan} loading={isPlanLoading}>Manage Your Subscription</Button>*/}
                                        {/*    }*/}
                                            <Button variant={"primary"} onClick={()=> setIsConfirmUpdate(true)}>Save</Button>
                                        </InlineStack>
                                    </BlockStack>
                                }
                            </BlockStack>
                        </Card>
                    </Layout.Section>

                    <Layout.Section>
                        <Card>
                            {
                                isLoading ? commonParagraph(1) :
                                    <BlockStack gap={"500"}>
                                        <BlockStack gap={"100"}>
                                            <InlineStack gap={"200"} blockAlign={"center"}>
                                                {Icons.linkIcon}
                                                <Text variant={"headingMd"}>Connected Accounts</Text>
                                            </InlineStack>
                                            <Text tone={"subdued"}>Enable Single Sign-On (SSO) for faster, secure, and more convenient access to your account.</Text>
                                        </BlockStack>
                                        <Divider/>
                                        <Box paddingBlockEnd={"200"}>
                                            <InlineStack gap={"200"} blockAlign={"center"} align={"space-between"}>
                                                <InlineStack gap={"200"} blockAlign={"center"}>
                                                    {Icons.googleLg} <Text variant={"headingMd"} fontWeight={"medium"}>Google</Text>
                                                    {profileDetails?.isGoogleConnect && <Badge tone={"success"}>Connected</Badge>}
                                                </InlineStack>
                                                <Button variant={"primary"} tone={profileDetails?.isGoogleConnect ? "critical" : ""} loading={loading === 'google'} onClick={() => !profileDetails?.isPasswordCreated ? setIsChangePassword(true) : connectGoogle("google")}>{profileDetails?.isGoogleConnect ? "Disconnect" : "Connect"}</Button>
                                            </InlineStack>
                                        </Box>
                                    </BlockStack>
                            }
                        </Card>
                    </Layout.Section>

                    <Layout.Section>
                        <Card>
                            {
                                isLoading ? commonParagraph(1) :
                                    <BlockStack gap={"500"}>
                                        <BlockStack gap={"400"}>
                                            <BlockStack gap={"100"}>
                                                <InlineStack gap={"200"} blockAlign={"center"}>
                                                    {Icons.authKey}
                                                    <Text variant={"headingMd"}>2FA (Two-Factor Authentication)</Text> {profileDetails.enabled2fa == 0 ? <Badge>Disabled</Badge> : <Badge tone={"success"}>Enabled</Badge>}
                                                </InlineStack>
                                                <Box><Text tone={"subdued"}>Secure your account with two-factor authentication, adding an extra layer of protection to your login process.</Text></Box>
                                            </BlockStack>


                                            {
                                                !TFADetails?.qrCode?.trim() || !TFADetails?.secrets2fa?.trim() ? null :
                                                    <BlockStack gap={"0"}>
                                                        <Text variant={"headingMd"}>Scan the QR code with your authenticator app</Text>
                                                        <img src={TFADetails?.qrCode} width={150} height={150} loading={"lazy"} alt={'QR code'}/>
                                                        <Text as={"span"}>
                                                            <Button variant={"monochromePlain"} disclosure={!isOpenCode ? "down" : 'up'} onClick={() => setIsOpenCode(!isOpenCode)}>Show code for manual configuration </Button>

                                                            <Collapsible
                                                                open={isOpenCode} id="basic-collapsible-code"
                                                                transition={{duration: '400ms', timingFunction: 'ease-in-out'}}
                                                            >
                                                                <Box paddingBlockStart={"100"} maxWidth={"230px"}><CopyCode value={TFADetails?.secrets2fa}/></Box>
                                                            </Collapsible>
                                                        </Text>
                                                    </BlockStack>
                                            }

                                        </BlockStack>
                                        <Text as={"span"}>
                                            <Button variant={"primary"} loading={profileDetails.loginType == 1 && (loading === '2FAD' || loading === '2FAE')}
                                                    onClick={profileDetails.loginType == 0 ? () => setModalType('authPass2FA') : handleGoogle}>{profileDetails.enabled2fa == 0 ? 'Set Up' : 'Disable'}</Button></Text>
                                    </BlockStack>
                            }
                        </Card>
                    </Layout.Section>

                    <Layout.Section>
                        <Card>
                            {
                                isLoading ? commonParagraph(1) :
                                <BlockStack gap={"500"}>
                                    <BlockStack gap={"100"}>
                                        <InlineStack gap={"200"} blockAlign={"center"}>
                                            {Icons.danger}
                                            <Text variant={"headingMd"}>Danger Zone</Text>
                                        </InlineStack>
                                        <Text tone={"subdued"}>This action will permanently delete your entire account, including all forms, submissions, and workspaces. This action cannot be undone.</Text>
                                    </BlockStack>
                                    <Text as={"span"}><Button variant={"primary"} tone={"critical"} onClick={()=> setModalType('DeleteAccount')}>Delete Account</Button></Text>
                                </BlockStack>
                            }
                        </Card>
                    </Layout.Section>

                </Layout>
            </Page>
        </Fragment>
    );
};

export default Settings;