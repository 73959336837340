import React, {useState, Fragment, useEffect} from 'react';
import {Page, Button, Text, InlineStack, BlockStack, Card, Box, Icon, Layout, Grid, Modal, Collapsible} from "@shopify/polaris";
import {CheckIcon,  XIcon} from '@shopify/polaris-icons';
import {apiService,} from "../../Utils/Constent";
import {Icons} from "../../Utils/Icons";
import {useSelector} from "react-redux";

const PlanAndPrice = () => {

    const planDetails = useSelector((state) => state.planDetails);

    const [planInterval, setPlanInterval] = useState("1")
    const [planType, setPlanType] = useState("")
    const [loading, setLoading] = useState('')
    const [isDownGradeModal, setIsDownGradeModal] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setPlanType(planDetails.planType)
    }, [planDetails]);

    const onActivePlan = async (loader) => {
        setLoading(loader)
        const response = await apiService.acceptPlan({planInterval: planInterval})
        if (response.success) {
            setLoading('')
            setIsDownGradeModal(false)
            window.open(response.data, "_self");
        } else {
            setLoading('')
        }
    }

    const Professional = [
        "Everything in Free Plan +",
        "Remove webform branding",
        "View partial submission",
        "Unlimited file upload",
        "Unlimited workspace",
        "Zapier integration",
        "Webhook integration",
        "Google sheets integration",
    ];

    const Free = [
        "Unlimited forms",
        "Unlimited submission",
        "Unlimited questions",
        "1 workspace",
        "Classic layout",
        "Card layout",
        "Customize forms",
        "Custom 'Thank You' page",
        "Form submission report",
        "File upload (5 MB)",
        "Add reCaptcha",
        "Email integration",
    ];

    const planFeaturesTable = [
        {
            title: 'Features',
            Features: [
                { label: 'Number of Forms', free: 'Unlimited', professional: 'Unlimited'},
                { label: 'Question per Form', free: 'Unlimited', professional: 'Unlimited'},
                { label: 'Monthly Response Limit', free: 'Unlimited', professional: 'Unlimited'},
                { label: 'Workspaces', free: '1', professional: 'Unlimited'},
                { label: 'WebForm Branding', free: true, professional: false},
                { label: 'Card Layout', free: true, professional: true},
                { label: 'Classic Layout', free: true, professional: true},
                { label: 'Email Notifications', free: true, professional: true},
                { label: 'File Uploads', free: '5 MB', professional: '10 MB'},
                { label: 'Custom ‘Thank you page’', free: true, professional: true},
                { label: 'Form Analytics', free: true, professional: true},
                { label: 'Add Form Logo', free: true, professional: true},
                { label: 'Add Banner', free: true, professional: true},
                { label: 'Form Customization', free: true, professional: true},
                { label: 'Captcha', free: true, professional: true},
                { label: 'Image Support', free: true, professional: true},
                { label: 'Video Support', free: true, professional: true},
                { label: 'Form Sharing', free: true, professional: true},
                { label: 'Custom HTML Support', free: false, professional: true},
                { label: 'View Partial Submission', free: false, professional: true},
                { label: 'Google Sheets Integration', free: false, professional: true},
                { label: 'Webhook Integration', free: false, professional: true},
                { label: 'Zapier Integration', free: false, professional: true},
                { label: 'Team Members (coming soon)', free: false, professional: true},
                { label: '24 x 7 support', free: true, professional: true},
            ],
        },
    ];

    const plansCard = [
        {
            title: "Free",
            price: "0",
            planType: "0",
            features: Free,
            active: planType == '0',
            description: "Start your form-building journey effortlessly with our Free Plan-ideal for beginners and those just getting started!",
            btnText: planType == '0' ? "Activated" : "Downgrade"
        },
        {
            title: "Professional",
            price: "19",
            planType: "1",
            features: Professional,
            active: planType == '1',
            description: "Get premium integrations, remove branding, view partial submissions, and enjoy unlimited file uploads and workspaces.",
            btnText:  planType == '1'? "Activated" : "Upgrade"
        }
    ];

    return (
        <Fragment>

            <Fragment>
                <Modal
                    open={isDownGradeModal}
                    onClose={() => setIsDownGradeModal(false)}
                    title="Are you sure you want to free plan?"
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => setIsDownGradeModal(false),
                        },
                    ]}
                    primaryAction={{
                        content: 'Yes i agree',
                        onAction: ()=> onActivePlan('agree'),
                        loading : loading === 'agree'
                    }}
                >
                    <Modal.Section>
                        <BlockStack gap={"300"}>
                            <Text variant={"headingMd"}>Loosing this features:</Text>
                            <BlockStack as={"span"} gap={"050"}>
                                {Professional.map((x,i) => {
                                    return (
                                        <InlineStack key={i} wrap={false} gap={"050"} blockAlign={"start"}>
                                            <Box as={"span"}><Icon source={XIcon} tone="critical"/></Box>
                                            <Text variant={"bodyMd"}>{x}</Text>
                                        </InlineStack>
                                    )
                                })}
                            </BlockStack>
                        </BlockStack>
                    </Modal.Section>
                </Modal>
            </Fragment>

            <Page title={"Plan & Price"} narrowWidth>
                <Layout>
                    <Fragment>
                        <Layout.Section>
                            <div className={"grid-plan"}>
                                <Grid gap={{xs: "30px", sm: "10px", md: "15px", lg: "15px", xl: "15px"}}>
                                    {
                                        (plansCard || []).map((x,i) => {
                                            return (
                                                <Grid.Cell columnSpan={{xs: 6, sm: 3, md: 3, lg: 6, xl: 6}} key={i}>
                                                    <div className={`plan-card ${x.active ? "active" : ""}`}
                                                         >
                                                        {planType ==  x.planType &&
                                                            <span className={"plan-badge"}>Activated</span>
                                                        }
                                                        <Card>
                                                            <BlockStack gap={"400"}>
                                                                <Text variant="headingLg" as="h5">{x.title}</Text>
                                                                <BlockStack gap={"200"}>
                                                                    {
                                                                        x.planType == 0 ?
                                                                            <Text as="p" tone="success" variant="heading2xl">${x.price}<Text variant="headingLg" as="span" tone={"text-inverse-secondary"}>/month</Text></Text>
                                                                            : <Fragment>
                                                                                <BlockStack gap={"0"}>
                                                                                    <Text as="p" tone="base" variant="headingMd" textDecorationLine={"line-through"}>${x.price}<Text variant="headingMd" as="span" tone={"base"}>/month</Text></Text>

                                                                                    <BlockStack>
                                                                                        <Text as="p" tone="success" variant="headingXl">100% Free</Text>
                                                                                        <Text variant="bodyLg" as="p" tone={"magic-subdued"}>Limited time offer</Text>
                                                                                    </BlockStack>
                                                                                </BlockStack>
                                                                            </Fragment>
                                                                    }

                                                                    <Text tone={"subdued"} variant={"bodySm"}>{x.description}</Text>
                                                                </BlockStack>

                                                                <BlockStack as={"span"} gap={"100"}>
                                                                    {(x.features || []).map((y,j) => {
                                                                        return (
                                                                            <InlineStack key={j+i} wrap={false} gap={"100"} blockAlign={"start"}>
                                                                                <Box as={"span"}><span className="Polaris-Icon">{Icons.tickMark}</span></Box>
                                                                                <Text variant={"bodySm"}>{y}</Text>
                                                                            </InlineStack>
                                                                        )
                                                                    })}
                                                                </BlockStack>

                                                                <Button fullWidth size={"large"} variant={"primary"} disabled={planType == x.planType} loading={loading == x.planType}
                                                                        onClick={()=> planType == '1' ?  setIsDownGradeModal(true) : onActivePlan(x.planType)}>{x.btnText}</Button>

                                                                {/*{*/}
                                                                {/*    x.planType == '1' && planType ==  '1' ?*/}
                                                                {/*        <InlineStack align={"center"}>*/}
                                                                {/*            <Button variant={"plain"} loading={loading === 'manage'}*/}
                                                                {/*                    onClick={()=> onActivePlan('manage')}>Manage Your Subscription</Button>*/}
                                                                {/*        </InlineStack>*/}
                                                                {/*    : ""*/}
                                                                {/*}*/}

                                                            </BlockStack>
                                                        </Card>
                                                    </div>
                                                </Grid.Cell>
                                            )
                                        })
                                    }

                                </Grid>
                            </div>
                        </Layout.Section>

                        <Layout.Section>
                            <InlineStack align={"center"}>
                                <Button textAlign={"center"} disclosure={open ? "up" : "down"} onClick={() => setOpen(!open)}>View Pricing Comparison</Button>
                            </InlineStack>

                            <Collapsible
                                open={open}
                                id="basic-collapsible"
                                transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                                expandOnPrint
                            >
                                &nbsp;
                                {(planFeaturesTable || []).map((x, i) => (
                                    <Box key={i}>
                                        <div className={"planPrice"}>
                                            <ul className={"PlanPriceList"}>
                                                <li className={"ppl_item"}>
                                                    <div className={"pplLabel"}>{x.title}</div>
                                                    <div className={"grow-up"}>Free</div>
                                                    <div className={"grow-up"}>Professional</div>
                                                </li>
                                                {(x.Features || []).map((y, j) => (
                                                    <li key={j} className={"ppl_item"}>
                                                        <div className={"pplLabel"}>{y.label}</div>
                                                        <div className={"grow-up"}>{y.free === true ? <Icon source={CheckIcon} tone={"primary"} /> : y.free === false ? <Icon source={XIcon} tone={"primary"} /> : y.free}</div>
                                                        <div className={"grow-up"}>{y.professional === false ? <Icon source={XIcon} tone={"primary"} /> : y.professional === true ? <Icon source={CheckIcon} tone={"primary"} /> : y.professional}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </Box>
                                ))}
                            </Collapsible>
                        </Layout.Section>
                    </Fragment>
                </Layout>
            </Page>
        </Fragment>
    );
};

export default PlanAndPrice;