import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {BlockStack, Box, Button, Icon, InlineStack, Scrollable, Popover, Text, TextField, Tooltip, Bleed,} from "@shopify/polaris";
import {ChevronDownIcon, ChevronUpIcon, DeleteIcon, DragHandleIcon, FileIcon, PlusCircleIcon, SearchIcon,} from "@shopify/polaris-icons";
import {commonParagraph} from "../../../../Utils/Loader";
import {blockBtnArray, elementBtnArray, findRef, useWindowSize} from "../CommonUse/CommonUse";

const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
}

const LeftBar = ({commonPropsLeftBar}) => {
    const {leftRef,isLoading, formData, blockArea, selectedPageIndex, selectedBlockIndex, setSelectedPageIndex, setSelectedBlockIndex, setSelectedFieldType, onSetUpMiddle,
         onUseThisBlock, setBlockArea, popoverActive, setPopoverActive, checkChange, oldBlockAreas, setDeletedFieldsArray, selectedHeadPageIndex, onDeletePage, findLeftRef} = commonPropsLeftBar;

    const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
    const [draggingBlock, setDraggingBlock] = useState({index:null,subIndex:null});
    const [draggingBlockIndex, setDraggingBlockIndex] = useState({index:null,subIndex:null});

    const [searchBlockEle, setSearchBlockEle] = useState('');
    const [filteredBlockBtnArray, setFilteredBlockBtnArray] = useState([]);
    const [filteredElementBtnArray, setFilteredElementBtnArray] = useState([]);
    const [isBlock, setIsBlock] = useState(true);

    const [isToggle, setIsToggle] = useState(false);
    const {width} = useWindowSize();

    const togglePopoverActive = useCallback((inx) => {
        setSearchBlockEle('')
        setFilteredElementBtnArray(elementBtnArray)
        setFilteredBlockBtnArray(blockBtnArray)
        setPopoverActive((prev) => (prev === inx ? null : inx));
    }, [popoverActive]);

    const titleObject = [
        ...blockBtnArray,
        ...elementBtnArray
    ].reduce((acc, {fieldType, label, icon}) => {
        acc[fieldType] = {value: fieldType, label, icon};
        return acc;
    }, {});

    useEffect(() => {
        let filterData = blockBtnArray
        if(formData.formLayout == 3){
            filterData = blockBtnArray.filter((x) => x.fieldType != 3 && x.fieldType != 14)
        }
        setFilteredBlockBtnArray(filterData)
        setFilteredElementBtnArray(elementBtnArray)
    },[isLoading])

    const handleEleChange = (val) => {
        setIsBlock(val)
        setSearchBlockEle('')
        let filterData = blockBtnArray
        if(formData.formLayout == 3){
            filterData = blockBtnArray.filter((x) => x.fieldType != 3 && x.fieldType != 14)
        }
        setFilteredBlockBtnArray(filterData)
        setFilteredElementBtnArray(elementBtnArray)
    }

    const handleSearchBlockEle = async (value, block) => {
        setSearchBlockEle(value)
        if (block) {
            const filteredBlockArray = blockBtnArray.filter((x) =>
                x.label.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredBlockBtnArray(filteredBlockArray);
        } else {
            const filteredElementArray = elementBtnArray.filter((x) =>
                x.label.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredElementBtnArray(filteredElementArray);
        }
    }

    const onDeleteBlock = async (event, block, blockIndex, record) => {
        if (event) {
            event.stopPropagation()
        }
        if (record.id && record.id !== '') {
            setDeletedFieldsArray(previous => [...previous, record?.id]);
        }
        let clone = {...blockArea};
        let updatedBlockArea = [...clone[block]];
        updatedBlockArea.splice(blockIndex, 1)
        clone[block] = updatedBlockArea;
        setBlockArea(clone)
        checkChange(clone, oldBlockAreas)
        setSelectedPageIndex(null);
        setSelectedBlockIndex(null);
        setSelectedFieldType(null)
    };

    // drag and drop
    const onDragStart = (event, x, pageIndex, blockIndex) => {
        setDraggingBlock({index: pageIndex, subIndex: blockIndex})
        const initialPosition = Number(event.currentTarget.dataset.position);
        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: blockArea[x]
        });
        setDraggingBlockIndex({index: pageIndex, subIndex: blockIndex})
        event.dataTransfer.setData("text/plain", '');
    };

    const onDragOver = (event, blockIndex) => {
        event.preventDefault();
        let newList = [...dragAndDrop.originalOrder];
        const draggedFrom = dragAndDrop.draggedFrom;
        const draggedTo = blockIndex;
        const itemDragged = newList[draggedFrom];
        newList.splice(draggedFrom, 1);
        newList.splice(draggedTo, 0, itemDragged);

        setDragAndDrop({
            ...dragAndDrop,
            updatedOrder: newList,
            draggedTo: draggedTo
        });
    };

    const onDrop = (x, pageIndex) => {
        const updatedBlockArea = {...blockArea};
        let hasUndefinedKey = false;
        for (const key of dragAndDrop?.updatedOrder) {
            if (key === undefined) {
                hasUndefinedKey = true;
                break;
            }
        }
        if (!hasUndefinedKey) {
            if(draggingBlock.index == pageIndex){
                updatedBlockArea[x] = dragAndDrop.updatedOrder;
                if(selectedPageIndex || selectedBlockIndex){
                    setSelectedFieldType(updatedBlockArea[selectedPageIndex][selectedBlockIndex].fieldType)
                }
                setBlockArea(updatedBlockArea);
                checkChange(updatedBlockArea, oldBlockAreas)
                setDragAndDrop({
                    ...dragAndDrop,
                    draggedFrom: null,
                    draggedTo: null,
                    isDragging: false
                });
                setDraggingBlockIndex({index: null, subIndex: null})
            }
        }
        setDraggingBlock({index: null, subIndex: null});
    };

    const onDragLeave = () => {
        setDragAndDrop({
            ...dragAndDrop,
            draggedTo: null
        });
    };

    const shouldIncludeRecaptcha = Object.values(blockArea).some(x => x?.some(y => y?.fieldType == "15"));

    return (
        <Fragment>
            <div style={{scrollPaddingTop: formData.formLayout == 2 ? '60px' : "10px"}} className={`left-bar ${(width <= 767) ? 'fixed' : ''} ${isToggle} ${formData.formLayout == 1 ? "formLayout1" : "formLayout2"}`} ref={leftRef || findLeftRef}>
                {
                    (width <= 767) && <div className={"sticky"}>
                        <Box paddingBlock={"300"} paddingBlockEnd={'200'} borderBlockEndWidth={"025"} borderColor={"border-secondary"} borderStyle={"solid"}>
                            <div onClick={() => setIsToggle(!isToggle)} className={"cursor-pointer"}>
                                <BlockStack  align={"center"} inlineAlign={"center"}>
                                    <Tooltip content={`${isToggle ? 'Close' : 'Open'} ${isBlock ? 'Blocks' : 'Elements'}`} dismissOnMouseOut>
                                        <Button variant={"monochromePlain"} icon={isToggle ? ChevronDownIcon : ChevronUpIcon}/>
                                    </Tooltip>
                                    <Text variant={"headingMd"}>{isBlock ? 'Blocks' : 'Elements'}</Text>
                                </BlockStack>
                            </div>
                        </Box>
                    </div>
                }

                {
                    (isLoading || formData.formLayout == 1 || formData.formLayout == 3) ? null : Object.keys(blockArea).length > 0 ?
                        <div className={"sticky-page"}>
                            <Box padding={"400"} paddingBlock={"400"} borderBlockEndWidth={"025"} borderBlockStartWidth={width <= 767 ? "0" : "025"} borderColor={"border-secondary"} borderStyle={"solid"}>
                                <InlineStack gap={"200"} blockAlign={"center"} align={"space-between"}><InlineStack gap={"200"} blockAlign={"center"}><Box><Icon source={FileIcon}/></Box><Text variant={"headingMd"}>Page - {selectedHeadPageIndex+1}</Text></InlineStack>
                                    <Bleed marginBlockEnd={"150"}><Tooltip content={"Delete Page"} dismissOnMouseOut><Button tone={"critical"} disabled={Object.keys(blockArea).length <= 1}  onClick={(e) => onDeletePage(e, selectedHeadPageIndex+1)} icon={DeleteIcon} variant="tertiary"/></Tooltip></Bleed></InlineStack>
                            </Box>
                        </div> : ""
                }

                <Box padding={"300"}>
                    {
                        isLoading ? commonParagraph(4) :
                            <div className={`active-${draggingBlock.index !== null && draggingBlock.subIndex === null}`}>
                                {Object.keys(blockArea).map((x, pageIndex) => {
                                    return (
                                        (selectedHeadPageIndex === pageIndex) ?
                                            <div className={`chev-main ${draggingBlock.index === pageIndex && draggingBlock.subIndex !== null}`} key={pageIndex}>
                                                <div className="parent-blk">
                                                    {blockArea[x].map((y, blockIndex) => {
                                                        return (
                                                            <div key={blockIndex}
                                                                 ref={el => findRef(el, findLeftRef, `${pageIndex}-${blockIndex}`)}
                                                                 className={`page-blocker ${draggingBlockIndex.index === pageIndex && draggingBlockIndex.subIndex === blockIndex ? 'dragging-block' : ''}`}
                                                                 data-position={blockIndex} draggable={true}
                                                                 onDragStart={(e) => onDragStart(e, x, pageIndex, blockIndex)}
                                                                 onDragOver={(e) => onDragOver(e, blockIndex)}
                                                                 onDrop={() => onDrop(x, pageIndex)}
                                                                 onDragLeave={() => onDragLeave()}
                                                            >
                                                                <div
                                                                    className={`card-pg ${(selectedPageIndex === pageIndex + 1 && selectedBlockIndex === blockIndex) ? "active" : ""}`}
                                                                    onClick={() => onSetUpMiddle(pageIndex + 1, blockIndex, y.fieldType, `${pageIndex}-${blockIndex}`, 'left')}
                                                                >
                                                                    <button className="drag-btn" onClick={e => e.stopPropagation()}>
                                                                        <Icon source={DragHandleIcon}/>
                                                                    </button>
                                                                    <span className="span-mid">
                                                                        <Icon source={titleObject[y.fieldType]?.icon || FileIcon}/>
                                                                        <span className="title-p">{titleObject[y.fieldType]?.label || "-"}</span>
                                                                    </span>
                                                                    <Button onClick={(e) => onDeleteBlock(e, x, blockIndex, y)} icon={DeleteIcon} variant="monochromePlain"/>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                    <Popover fluautofocusTarget="none"
                                                             onClose={() => togglePopoverActive(pageIndex)}
                                                             preferredAlignment="right" preferredPosition="below"
                                                             fluidContent sectioned={false} fullHeight
                                                             active={popoverActive === pageIndex} activator={
                                                        <button className={`add-block ${popoverActive === pageIndex ? "active" : ""}`} onClick={() => togglePopoverActive(pageIndex)}>
                                                            <Icon source={PlusCircleIcon}/>Add Block
                                                        </button>
                                                    }>
                                                        <InlineStack wrap={false}>
                                                            <Box padding={"200"} borderWidth={"0"}
                                                                 minWidth={"250px"}>
                                                                <BlockStack gap={"300"}>
                                                                    <BlockStack gap={"300"}>
                                                                        <TextField
                                                                            clearButton autoComplete="off" value={searchBlockEle} prefix={<Icon source={SearchIcon}/>}
                                                                            onClearButtonClick={() => handleEleChange(isBlock)}
                                                                            onChange={(value) => handleSearchBlockEle(value, isBlock)}
                                                                            placeholder={`Search ${isBlock ? 'blocks' : 'elements'}`}
                                                                        />
                                                                            {
                                                                                formData.formLayout != 3 &&
                                                                                    <InlineStack wrap={false} gap={"100"}>
                                                                                        <Button size={"large"} pressed={isBlock} fullWidth variant={!isBlock ? "secondary" : "tertiary"} onClick={() => handleEleChange(true)}>Blocks</Button>
                                                                                        <Button size={"large"} pressed={!isBlock} variant={!isBlock ? "tertiary" : "secondary"} fullWidth onClick={() => handleEleChange(false)}>Elements</Button>
                                                                                    </InlineStack>
                                                                            }
                                                                    </BlockStack>
                                                                    <Scrollable shadow style={{maxHeight: '300px'}} focusable={false}>
                                                                        <BlockStack gap={"050"}>
                                                                            {(isBlock ? filteredBlockBtnArray : filteredElementBtnArray).map((btnX, i) => {
                                                                                return (
                                                                                    <button key={i} disabled={btnX.fieldType == 15 && shouldIncludeRecaptcha} onClick={() => onUseThisBlock(btnX, "addBlock", x, pageIndex)} className={`block-btn`}>
                                                                                        <Icon source={btnX.icon}/>
                                                                                        <Text>{btnX.label}</Text>
                                                                                    </button>
                                                                                )
                                                                            })}

                                                                            {(filteredBlockBtnArray.length === 0 || filteredElementBtnArray.length === 0) ?
                                                                                <Box paddingBlock={"400"} paddingBlockEnd={"600"}><Text alignment={"center"} variant={"headingMd"}>Couldn't Find {isBlock ? "Block" : " Element"}</Text></Box>:""}
                                                                        </BlockStack>
                                                                    </Scrollable>
                                                                </BlockStack>
                                                            </Box>
                                                        </InlineStack>
                                                    </Popover>
                                                </div>
                                            </div>
                                            : null
                                    )
                                })}
                            </div>
                    }
                </Box>
            </div>
        </Fragment>
    );
};

export default LeftBar;