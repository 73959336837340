import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {baseUrl, getToken, getTokenVerify} from "../../Utils/Constent";

const Protected = () => {
    return getToken() ? <Outlet/> : getTokenVerify("token-verify-onboarding") ? <Navigate to={`${baseUrl}/onboarding`}/> : getTokenVerify("token-verify-2fa") ? <Navigate to={`${baseUrl}/verify-2fa`}/> :<Navigate to={`${baseUrl}/login`}/>

};

export default Protected;



