import React, {Fragment, useEffect, useState} from 'react';
import {BlockStack, Box, Button, ButtonGroup, Divider, DropZone, InlineStack, Text, TextField} from "@shopify/polaris";
import {imagePath, isEmpty, onKeyFire} from "../../../../Utils/Constent";

const ImgStyle = ({commonProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles} = commonProps;
    const {imgURL, imgLink, imgType, fieldSize} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const [imgConvert, setImgConvert] = useState( '');
    const [error, setError] = useState('')
    const [submitTrue, setSubmitTrue] = useState(false)

    const imgStyle = {
        maxWidth: "100px",
        maxHeight: '90%',
        width: "100%",
    }

    useEffect(() => {
        setImgConvert(imgLink)
        setError('')
        if(imgLink?.trim() !== ''){
            setSubmitTrue(true)
        }
    }, [imgLink, imgType, selectedPageIndex, selectedBlockIndex])

    useEffect(() => {
        setError('')
    }, [imgConvert, selectedPageIndex, selectedBlockIndex])


    const onSubmitImg = () => {
        if (!imgConvert.length || (typeof imgConvert === 'string' && imgConvert?.trim() === '')) {
            setError('Enter image URL');
            setSubmitTrue(false)
            return;
        }

        const img = new Image();
        img.onload = function() {
            handleChangeStyles('imgLink', imgConvert);
            setError('');
            setSubmitTrue(true)
        };
        img.onerror = function() {
            setError('Invalid image URL');
            setSubmitTrue(false)
        };
        img.src = imgConvert;
    };

    const handleCheckImg = (value) => {
        setImgConvert(value)
    };

    const uploadedImgURL = imgURL && imgURL?.name ?
        (<div className="upload-center"><img style={imgStyle} src={URL.createObjectURL(imgURL)}/></div>) :
        imgURL?.length > 0 ? (<div className="upload-center"><img style={imgStyle} src={`${imagePath}${imgURL}`}/></div>):
            <DropZone.FileUpload actionTitle={"Add image"}/> ;

    const handleDropZoneDrop = (_dropFiles, acceptedFiles, _rejectedFiles) => {
        handleChangeStyles('imgURL', acceptedFiles && acceptedFiles.length ? acceptedFiles[0] : '')
    }

    const onRemoveImg = () => {
        handleChangeStyles('imgURL', '')
    }

    const onRemoveURL = () => {
        handleCheckImg('')
        setImgConvert('')
        handleChangeStyles('imgLink', '');
        setSubmitTrue(false)
    }

    const onChangeType = (type) => {
        handleChangeStyles("imgType", type)
    }

    return (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"200"}>
                    <ButtonGroup variant="segmented" fullWidth>
                        <Button
                            fullWidth size={"large"}
                            pressed={imgType == '0'}
                            onClick={() => onChangeType('0')}
                        >
                            Upload
                        </Button>
                        <Button
                            fullWidth size={"large"}
                            pressed={imgType == '1'}
                            onClick={() => onChangeType('1')}
                        >
                            URL
                        </Button>
                    </ButtonGroup>

                    {
                        imgType == '0' ?
                            <Fragment>
                                <InlineStack as={"span"} gap={"100"} align={"space-between"}><label>Upload Image</label>
                                    {((imgURL && imgURL?.name) || imgURL?.length > 0 ) && <Button variant={"plain"} onClick={onRemoveImg}>Remove Image</Button>}
                                </InlineStack>
                                <DropZone
                                    label={'Upload Image'} labelHidden
                                    type={"image"} accept="image/*"
                                    onDrop={handleDropZoneDrop}
                                >
                                    {uploadedImgURL}
                                </DropZone>
                            </Fragment> : imgType == '1' ?
                            <Fragment>
                                <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, onSubmitImg)}>
                                    <BlockStack gap={"100"}>
                                        <InlineStack gap={"100"} align={"space-between"}><Text>Image URL</Text>
                                            {!isEmpty(imgConvert) && submitTrue ? <Button onClick={onRemoveURL} variant={"plain"}>Remove URL</Button> : ""}
                                        </InlineStack>
                                        <TextField
                                            label={"Image URL"} labelHidden
                                            placeholder={"Enter Image URL"}
                                            type={'url'}
                                            value={imgConvert}
                                            onChange={(value) => handleCheckImg(value)}
                                            autoComplete="off"
                                            error={error}
                                        />
                                    </BlockStack>
                                </Box>
                                <Button variant={"primary"} onClick={onSubmitImg}>Submit URL</Button>
                            </Fragment>: ""
                    }
                </BlockStack>
            </Box>

            <Divider />
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"100"}>
                    <Text>Block Size</Text>
                    <ButtonGroup variant="segmented" fullWidth>
                        {['medium', 'fullwidth'].map((x) => (
                            <Button
                                key={x} fullWidth size={"large"}
                                pressed={fieldSize === x}
                                onClick={() => handleChangeStyles("fieldSize", x,)}
                            >
                                {x === "fullwidth" ? "Full-width" :  x.charAt(0).toUpperCase() + x.slice(1)}
                            </Button>
                        ))}
                    </ButtonGroup>
                </BlockStack>
            </Box>
            <Divider />
        </Fragment>
    );
};

export default ImgStyle;