import {CalendarIcon, CaretDownIcon, CheckboxIcon, ComposeIcon, EmailIcon, FaviconIcon, ImageIcon, LinkIcon,
    MenuIcon, MinusIcon, PageIcon, PhoneIcon, PlayCircleIcon, ReferralCodeIcon, SelectIcon, StarIcon, StatusActiveIcon, TextBlockIcon, TextTitleIcon, UploadIcon} from "@shopify/polaris-icons";
import {Icons} from "../../../../Utils/Icons";
import {useEffect, useState} from "react";
import {webDomain} from "../../../../Utils/Constent";


export const blockBtnArray = [
    {
        icon: MinusIcon,
        fieldType: "1",
        label: 'Text Field',
    },
    {
        icon: MenuIcon,
        fieldType: "2",
        label: 'Text Area',
    },
    {
        icon: SelectIcon,
        fieldType: "3",
        label: 'Multiple Choice',
    },
    {
        icon: EmailIcon,
        fieldType: "4",
        label: 'Email Address',
    },
    {
        icon: PhoneIcon,
        fieldType: "5",
        label: 'Phone Field',
    },
    {
        icon: StatusActiveIcon,
        fieldType: "6",
        label: 'Single Choice',
    },
    {
        icon: CheckboxIcon,
        fieldType: "7",
        label: 'Checkboxes',
    },
    {
        icon: CaretDownIcon,
        fieldType: "8",
        label: 'Dropdown',
    },
    {
        icon: CalendarIcon,
        fieldType: "9",
        label: 'Date Picker',
    },
    {
        icon: ReferralCodeIcon,
        fieldType: "10",
        label: 'Number Field',
    },
    {
        icon: LinkIcon,
        fieldType: "11",
        label: 'Website',
    },
    {
        icon: StarIcon,
        fieldType: "12",
        label: 'Rating',
    },
    {
        icon: UploadIcon,
        fieldType: "13",
        label: 'File Upload',
    },
    {
        icon: ComposeIcon,
        fieldType: "14",
        label: 'Digital Signature',
    },
    {
        icon: FaviconIcon,
        fieldType: "15",
        label: 'Captcha Verification',
    },
];

export const elementBtnArray = [
    {
        icon: TextTitleIcon,
        fieldType: "16",
        label: 'Heading 1',
    },
    {
        icon: TextTitleIcon,
        fieldType: "17",
        label: 'Heading 2',
    },
    {
        icon: TextTitleIcon,
        fieldType: "18",
        label: 'Heading 3',
    },
    {
        icon: TextBlockIcon,
        fieldType: "19",
        label: 'Paragraph',
    },
    {
        icon: MinusIcon,
        fieldType: "20",
        label: 'Divider',
    },
    {
        icon: ImageIcon,
        fieldType: "21",
        label: 'Image',
    },
    {
        icon: PlayCircleIcon,
        fieldType: "22",
        label: 'Video Embed',
    },
];

export const descFieldType = {
    "1": 'Use this field for short text responses, such as names or titles.',
    "2": 'Use this field for longer text responses, like comments or feedback.',
    "3": 'Allow users to select multiple options from a set of choices.',
    "4": "Capture user's email addresses for communication and notifications.",
    "5": "Collect user's phone numbers for contact purposes.",
    "6": 'Let users select one option from a list of choices.',
    "7": 'Enable users to check multiple options that apply to them.',
    "8": 'Provide a compact way for users to select one option from a dropdown menu.',
    "9": 'Allow users to select a date easily from a calendar interface.',
    "10": 'Capture numeric values, such as quantities or ages.',
    "11": 'Collect web addresses for links to websites or resources.',
    "12": 'Enable users to rate their experience or satisfaction on a scale.',
    "13": 'Allow users to upload files directly through the form.',
    "14": 'Collect user signatures electronically for consent or approval.',
    "15": 'Protect your form from spam by verifying user submissions with Captcha.',
    "16": 'Use this for the main titles or prominent sections of your content.',
    "17": 'Ideal for subheadings or breaking up sections under the main title.',
    "18": 'Use for smaller subsections to organize content more effectively.',
    "19": 'Add longer blocks of text to provide information or explanations.',
    "20": 'Organize your form by creating breaks between different sections.',
    "21": 'Insert images to make your content more engaging and visually appealing.',
    "22": 'Integrate videos directly into your form for enhanced content delivery.',
    default: 'Make you form your own by adding your brand color and logo.'
};

export const labelFieldType = {
    "1": 'Text Field',
    "2": 'Text Area',
    "3": 'Multiple Choice',
    "4": 'Email Address',
    "5": 'Phone Field',
    "6": 'Single Choice',
    "7": 'Checkboxes ',
    "8": 'Dropdown',
    "9": 'Date Picker',
    "10": 'Number Field',
    "11": 'Website',
    "12": 'Rating',
    "13": 'File Upload',
    "14": 'Digital Signature',
    "15": 'Captcha Verification',
    "16": 'Heading 1',
    "17": 'Heading 2',
    "18": 'Heading 3',
    "19": 'Paragraph',
    "20": 'Divider',
    "21": 'Image',
    "22": 'Video Embed',
    default: 'Type a question'
};

export const fieldSizeFieldType = {
    "15": 'fullwidth',
    "16": 'fullwidth',
    "17": 'fullwidth',
    "18": 'fullwidth',
    "20": 'fullwidth',
    default: 'medium',
};

export const placeholderFieldType = {
    "3": 'Add options',
    "8": '-Select-',
    "11": `${webDomain}`,
    "13": 'Add files',
    "21": 'Choose File',
    default: '',
};

export const iconFieldType = {
    "1": MinusIcon,
    "2": MenuIcon,
    "3": SelectIcon,
    "4": EmailIcon,
    "5": PhoneIcon,
    "6": StatusActiveIcon,
    "7": CheckboxIcon,
    "8": CaretDownIcon,
    "9": CalendarIcon,
    "10": ReferralCodeIcon,
    "11": LinkIcon,
    "12": StarIcon,
    "13": UploadIcon,
    "14": ComposeIcon,
    "15": FaviconIcon,
    "16": TextTitleIcon,
    "17": TextTitleIcon,
    "18": TextTitleIcon,
    "19": TextBlockIcon,
    "20": MinusIcon,
    "21": ImageIcon,
    "22": PlayCircleIcon,
    default: PageIcon
};

const radioCheckOptions = [
    {label: "First Choice", value: "First Choice"},
    {label: "Second Choice", value: "Second Choice"},
    {label: "Third Choice", value: "Third Choice"},
]

export const optionsFieldType = {
    "3": radioCheckOptions,
    "6": radioCheckOptions,
    "7": radioCheckOptions,
    "8": radioCheckOptions,
    default: []
};

export const iconsRatingType = {
    "1": Icons.StarIcon,
    "2": Icons.LightningIcon,
    "3": Icons.ShieldIcon,
    "4": Icons.HeartIcon,
    "5": Icons.FlagIcon,
    "6": Icons.BulbIcon,
    default: Icons.StarIcon
};

export const allowedType = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.svg',
    '.heic',
    '.webp',
    '.bmp',
    '.psd',
    '.mp4',
    '.mov',
    '.webm',
    '.mp3',
    '.wav',
    '.m4a',
    '.txt',
    '.csv',
    '.html',
    '.xml',
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.zip',
    '.rar',
    '.json',
    '.gzip',
    '.odt',
]

export const isContentEmpty = (content) => {
    const strippedContent = content?.replace(/<[^>]*>/g, '')?.trim();
    return strippedContent === '';
};

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
}

export const scrollToBottom = (ref) => {
    if (ref?.current) {
        const element = ref.current;
        setTimeout(() => {
            element.scrollTo({
                top: element.scrollHeight - element.clientHeight,
                behavior: 'smooth'
            });
        }, 0);
    }
};

export const findRef = (el, ref, index) => {
    if (!ref.current) {
        ref.current = {};
    }
    ref.current[index] = el
};

export const findRefIndex = (ref, refIndex) => {
    if (ref?.current[refIndex]) {
        const element = ref.current[refIndex];
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
};

export const onCopy = (inputID, parentID, setMessage) => {
    const copyCode = document.getElementById(parentID);
    copyCode.classList.add("copy-true");
    const copyText = document.getElementById(inputID);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setMessage('Copied!');
    setTimeout(() => {
        copyCode.classList.remove("copy-true");
        copyText.setSelectionRange(0, 0);
    }, 3000);
};

export const updateStyles = (selectors, propertyName, value) => {
    selectors.forEach(selector => {
        const elements = document.querySelectorAll(selector);
        elements.forEach(element => {
            element.style.setProperty(propertyName, value);
        });
    });
};