import React, {Fragment} from 'react';

const VideoPlay = ({commonListProps}) => {
    const {blockArea, selectedPageIndex, selectedBlockIndex} = commonListProps;
    const {style, videoURL} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};
    const {controlsVideo = "0", autoPlayVideo = "1", muteVideo = "1"} = style;

    let videoType = '';
    let embed_link = '';
    if (videoURL !== '' || videoURL != null) {
        if (videoURL?.includes('player.vimeo.com')) {
            videoType = 'vimeo';
            embed_link = videoURL.split('video/')[1]?.split('?')[0];
        }
        else if (videoURL?.includes('vimeo.com')) {
            videoType = 'vimeo';
            embed_link = videoURL.split('.com/')[1];
        } else if (videoURL?.includes('youtube.com/watch')) {
            videoType = 'youtube';
            if (videoURL.includes('list=')) {
                const videoIdIndex = videoURL.indexOf('v=') + 2;
                const ampersandIndex = videoURL.indexOf('&', videoIdIndex);
                embed_link = ampersandIndex !== -1 ? videoURL.substring(videoIdIndex, ampersandIndex) : videoURL.substring(videoIdIndex);
            } else {
                embed_link = videoURL.split('v=')[1];
            }
        } else if (videoURL?.includes('youtube.com/embed')) {
            videoType = 'youtube';
            embed_link = videoURL.split('embed/')[1]?.split('?')[0];
        } else if (videoURL?.includes('youtu.be/')) {
            videoType = 'youtube';
            embed_link = videoURL.split('.be/')[1]?.split('?')[0];
        } else if (videoURL.includes('youtube.com/live') || videoURL.includes('youtu.be/live')) {
            videoType = 'youtube';
            let parts = videoURL?.split('/').pop();
            embed_link = parts?.split('?')[0];
        } else {
            videoType = 'mp4';
        }
    }

    return (
        <Fragment>
            <div className={`videoWidget ${videoType === 'mp4' ? 'videoEmbed' : 'iframeEmbed'}`}>
                {
                    videoType === 'youtube' ?
                        <iframe
                            src={`https://www.youtube.com/embed/${embed_link}?autoplay=${autoPlayVideo}&mute=${muteVideo}&loop=1&color=white&controls=${controlsVideo}&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&background=1&playlist=${embed_link}`}
                            title={'Youtube Video Preview'} frameBorder="0" allowFullScreen
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                        :
                        videoType === 'vimeo' ?
                            <iframe
                                src={`https://player.vimeo.com/video/${embed_link}?autoplay=${autoPlayVideo}&loop=1&title=0&byline=0&portrait=0&muted=${muteVideo}&transparent=0&background=${controlsVideo == 0 ? 1 : 0}`}
                                title={'Vimeo Video Preview'} data-ready="true"
                                frameBorder="0" allow="autoplay;fullscreen;" allowFullScreen
                            /> :
                            <video datatype="mp4" src={videoURL} title={'Video Preview'} width="100%" playsInline loop
                                   muted={muteVideo == 1} autoPlay={autoPlayVideo == 1} controls={embed_link?.trim() === '' ? true : controlsVideo == 1}/>
                }
            </div>
        </Fragment>
    );
};

export default VideoPlay;