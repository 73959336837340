import React, {Fragment, useState, useCallback, useEffect, useContext} from 'react';
import {BlockStack, TextField, InlineStack, Button, Text, Box} from '@shopify/polaris';
import {apiService, baseUrl, emailRegExp, onKeyFire, OR} from "../../Utils/Constent";
import AuthFrame from "../AuthStructure/AuthFrame";
import {useNavigate} from "react-router-dom";
import {HideIcon, ViewIcon} from "@shopify/polaris-icons";
import WithGoogle from "./WithGoogle";
import {setUserDetails} from "../../Redux/Action/Action";
import {useDispatch} from "react-redux";
import {ToastContext} from "../../Components/Common/ToastProvider";
import { detectIncognito } from "detectincognitojs";

const initialState = {
    email: "",
    password: "",
};

const Login = () => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);
    const navigate = useNavigate();
    const [authDetails, setAuthDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [isShowPaas, setIsShowPaas] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'WebForm | Login';
        detectIncognito().then((result) => {
            setIsPrivate(result.isPrivate);
        });
    }, []);

    const formValidate = (name, value) => {
        switch (name) {
            case "email":
                if (!value.trim()) {
                    return "Email is required.";
                } else if (!value.match(emailRegExp)) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            case "password":
                if (!value || value.trim() === "") {
                    return "Password is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
            setAuthDetails(prevTokenDetails => ({
                ...prevTokenDetails,
                [name]: value
            }));
            setErrors(formError => ({...formError, [name]: '',}));
        },
        [authDetails],
    );

    const onBlurChange = useCallback((name, value) => {
            setErrors(formError => ({
                ...formError,
                [name]: formValidate(name, value),
            }));
        },
        [authDetails],
    );

    const onNavigate = (url) => {
        navigate(`${baseUrl}/${url}`)
    }

    const handleLogin = async () => {
        let errorData = {};
        Object.keys(authDetails).map((x) => {
            let error = formValidate(x, authDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setIsLoading(true);
        const payload = {
            email: authDetails.email,
            password: authDetails.password,
            isPrivate: isPrivate,
        };
        const response = await apiService.login(payload);
        if (response.success === true) {
            setIsLoading(false);
            setActive(true);
            setAuthDetails(initialState);
            setIsError(false)
            dispatch(setUserDetails(response?.data));
            let userDetails = {...response?.data};
            delete userDetails?.token;
            localStorage.setItem("user-details", JSON.stringify(userDetails));
            if(response?.data?.boarding == 0){
                setMessage("You’re logged in! 🚀");
                localStorage.setItem("token-verify-onboarding", response?.data?.token);
                navigate(`${baseUrl}/onboarding`);
            } else {
                if (response?.data?.enabled2fa == 1) {
                    localStorage.setItem("token-verify-2fa", response?.data?.token);
                    navigate(`${baseUrl}/verify-2fa`);
                } else {
                    setMessage("You’re logged in! 🚀");
                    localStorage.setItem("token", response?.data?.token);
                    navigate(`${baseUrl}/dashboard`);
                }
            }
        } else {
            setIsLoading(false);
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    };

    return (
        <Fragment>
            <AuthFrame title={"Sign In To Your Account"}
                       subTitle={<Text as={"span"} tone={"subdued"}>Or <button className={"create-btn"} onClick={()=>onNavigate('register')}>Create a new account</button></Text>}>
                <Box as={"span"} width={"100%"}>
                    <BlockStack gap={"300"}>
                        <Box as={"span"} width={"100%"}>
                            <TextField
                                label={"Email"}
                                type={"email"}
                                placeholder={"Email"}
                                value={authDetails.email}
                                error={formError.email} onBlur={(e) => onBlurChange("email", e.target.value)}
                                onChange={(value) => onHandleChange("email", value)}
                            />
                        </Box>
                        <Box as={"span"} width={"100%"}>
                            <BlockStack gap={"200"}>
                                <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, handleLogin)}>
                                    <TextField
                                        label={"Password"}
                                        placeholder={"Password"}
                                        value={authDetails.password}  type={isShowPaas ? "text" : "password"}
                                        error={formError.password} onBlur={(e) => onBlurChange("password", e.target.value)}
                                        onChange={(value) => onHandleChange("password", value)}
                                        suffix={
                                            <Box paddingBlockStart={"100"}><Button icon={!isShowPaas ? ViewIcon : HideIcon} variant={"plain"} onClick={()=>setIsShowPaas(!isShowPaas)}/></Box>
                                        }
                                    />
                                </Box>
                                <InlineStack align={"end"}><Button variant={"monochromePlain"} onClick={()=>onNavigate('forgot-password')}>Forgot password?</Button></InlineStack>
                            </BlockStack>
                        </Box>

                        <Button size={"large"} variant={"primary"}  onClick={isGoogleLoading ? null : handleLogin} loading={isLoading}>Log in</Button>
                        <Fragment>{OR}</Fragment>

                        <WithGoogle {...{text: 'Login with Google',isLoading, isGoogleLoading, setIsGoogleLoading}} />
                    </BlockStack>
                </Box>
            </AuthFrame>
        </Fragment>
    );
};

export default Login;