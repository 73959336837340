import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {AutoSelection, BlockStack, Box, Button, ButtonGroup, Checkbox, Combobox, Divider, EmptySearchResult, InlineStack,
    Listbox, Select, Tag, Text, TextField,} from "@shopify/polaris";
import {allowedType} from "../CommonUse/CommonUse";
import ReactQuill from "react-quill";

const FileUploadStyle = ({commonProps}) => {
    const { blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles,formData } = commonProps;
    const {fieldSize, placeholder, title, fileFormats, fileUploadMinLimit, fileUploadMaxLimit, isRequired, description, errorMessage} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const fileFormatsArray = useMemo(() => {
        return fileFormats?.trim() !== '' ? fileFormats?.split(',').map(format => format?.trim()) : [];
    }, [fileFormats]);

    const [value, setValue] = useState('');
    const [suggestion, setSuggestion] = useState('');

    let uploadMaxLimit = [];
    for (let i = 1; i <= 5; i++) {
        const disabled = i < parseInt(fileUploadMinLimit, 10);
        uploadMaxLimit.push({ label: `${i}`, value: `${i}`, disabled });
    }

    let uploadMinLimit = [];
    for (let i = 0; i <= 5; i++) {
        const disabled = i > parseInt(fileUploadMaxLimit, 10);
        uploadMinLimit.push({ label: `${i === 0 ? "N/A" : i}`, value: `${i}`, disabled });
    }

    const handleActiveOptionChange = useCallback(
        (activeOption) => {
            const activeOptionIsAction = activeOption === value;

            if (!activeOptionIsAction && !fileFormatsArray.includes(activeOption)) {
                setSuggestion(activeOption);
            } else {
                setSuggestion('');
            }
        },
        [value, fileFormatsArray],
    );

    const updateSelection = useCallback(
        (selected) => {
            const nextSelectedTags = new Set([...fileFormatsArray]);

            if (nextSelectedTags.has(selected)) {
                nextSelectedTags.delete(selected);
            } else {
                nextSelectedTags.add(selected);
            }
            handleChangeStyles('fileFormats', [...nextSelectedTags]?.join(', '))
            setValue('');
            setSuggestion('');
        },
        [fileFormatsArray],
    );

    const removeTag = useCallback(
        (tag) => () => {
            updateSelection(tag);
        },
        [updateSelection],
    );

    const getAllTags = useCallback(() => {
        const savedTags = [...allowedType];
        return [...new Set([...savedTags, ...fileFormatsArray].sort())];
    }, [fileFormatsArray]);

    const formatOptionText = useCallback(
        (option) => {
            const trimValue = value.trim().toLocaleLowerCase();
            const matchIndex = option.toLocaleLowerCase().indexOf(trimValue);

            if (!value || matchIndex === -1) return option;

            const start = option.slice(0, matchIndex);
            const highlight = option.slice(matchIndex, matchIndex + trimValue.length);
            const end = option.slice(matchIndex + trimValue.length, option.length);

            return (
                <p>{start}
                    <Text fontWeight="bold" as="span">{highlight}</Text>
                    {end}
                </p>
            );
        },
        [value],
    );

    const escapeSpecialRegExCharacters = useCallback(
        (value) => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
        [],
    );

    const optionsArray = useMemo(() => {
        let list;
        const allTags = getAllTags();
        const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), 'i');

        if (value) {
            list = allTags.filter((tag) => tag.match(filterRegex));
        } else {
            list = allTags;
        }

        return [...list];
    }, [value, getAllTags, escapeSpecialRegExCharacters]);


    const verticalContentMarkup =
        fileFormatsArray.length > 0 ? (
            <InlineStack gap={"100"} alignment="center">
                {fileFormatsArray.map((tag) => (
                    <Tag key={`option-${tag}`} onRemove={removeTag(tag)}>
                        {tag}
                    </Tag>
                ))}
            </InlineStack>
        ) : null;

    const optionMarkup =
        optionsArray.length > 0
            ? optionsArray.map((option) => {
                return (
                        <Listbox.Option
                            key={option}
                            value={option}
                            selected={fileFormatsArray.includes(option)}
                            accessibilityLabel={option}
                        >
                            <Listbox.TextOption selected={fileFormatsArray.includes(option)}>
                                {formatOptionText(option)}
                            </Listbox.TextOption>
                        </Listbox.Option>
                );
            })
            : null;

    const emptyStateMarkup = optionMarkup ? null : (
        <EmptySearchResult
            title="No found"
            description={`No type found matching "${value}"`}
        />
    );

    const listBoxMarkup = optionMarkup || emptyStateMarkup ? (
            <Listbox
                autoSelection={AutoSelection.None}
                onSelect={updateSelection}
                onActiveOptionChange={handleActiveOptionChange}
            >
                {emptyStateMarkup}
                {optionMarkup}
            </Listbox>
        ) : null;

    return (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <TextField
                    label="Field Name"
                    placeholder={"Type a label"}
                    value={title}
                    onChange={(value) => handleChangeStyles('title', value)}
                    autoComplete="off"
                />
            </Box>
            <Divider />

            {
                formData.formLayout != 3 &&
                <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack>
                            <div className="Polaris-Labelled__LabelWrapper">
                                <div className="Polaris-Label">
                                    <label className={`Polaris-Label__Text`}>Description</label>
                                </div>
                            </div>
                            <ReactQuill
                                placeholder={"Enter description here"}
                                className={`ql-field"`}
                                value={description}
                                modules={{
                                    toolbar: {container: [["bold", "underline", "italic", 'link']],},
                                    clipboard: { matchVisual: false }
                                }}
                                onChange={(newValue, delta, source) => {
                                    if (source === 'user') {
                                        handleChangeStyles('description', newValue)
                                    }
                                }}
                            />
                        </BlockStack>
                    </Box>
                    <Divider />
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <BlockStack gap={"100"}>
                            <Text>Field Width</Text>
                            <ButtonGroup variant="segmented" fullWidth>
                                {['small', 'medium', 'large', 'fullwidth'].map((x) => (
                                    <Button
                                        key={x} fullWidth size={"large"}
                                        pressed={fieldSize === x}
                                        onClick={() => handleChangeStyles("fieldSize", x,)}
                                    >
                                        {x === "fullwidth" ? "Full-width" :  x.charAt(0).toUpperCase() + x.slice(1)}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </BlockStack>
                    </Box>
                    <Divider />
                </Fragment>
            }

            <Box padding={"400"} paddingBlockStart={"200"}>
                <TextField
                    label="Placeholder Text"
                    placeholder={"Type a placeholder"}
                    value={placeholder}
                    onChange={(value) => handleChangeStyles('placeholder', value)}
                    autoComplete="off"
                />
            </Box>
            <Divider />
            <Box padding={"400"} paddingBlockStart={"200"}>
                <Combobox
                    allowMultiple
                    activator={
                        <Combobox.TextField
                            autoComplete="off"
                            label="Accepted File Formats"
                            value={value}
                            suggestion={suggestion}
                            placeholder="Search file type"
                            verticalContent={verticalContentMarkup}
                            onChange={setValue}
                            helpText={"By default, all file types are accepted."}
                        />
                    }
                >
                    {listBoxMarkup}
                </Combobox>
            </Box>

            <Divider />
            <Box padding={"400"} paddingBlockStart={"200"} paddingBlockEnd={"200"}>
                <div className="Polaris-Labelled__LabelWrapper">
                    <div className="Polaris-Label">
                        <label className={`Polaris-Label__Text`}>Upload Limit</label>
                    </div>
                </div>
                <InlineStack gap={"200"} wrap={false}>
                    <div className={"w-100"}>
                        <Select
                            options={uploadMinLimit}
                            onChange={(value) => handleChangeStyles('fileUploadMinLimit', value)}
                            value={fileUploadMinLimit}
                            helpText={"Min"}
                        />
                    </div>
                    <div className={"w-100"}>
                        <Select
                            options={uploadMaxLimit}
                            onChange={(value) => handleChangeStyles('fileUploadMaxLimit', value)}
                            value={fileUploadMaxLimit}
                            helpText={"Max"}
                        />
                    </div>
                </InlineStack>
            </Box>

            <Divider />
            <Box paddingBlockEnd={"200"} paddingBlockStart={"200"} padding={"400"}>
                <Checkbox
                    label="Required Field"
                    checked={isRequired == 1}
                    onChange={(checked) => handleChangeStyles('isRequired', checked ? "1" : "0")}
                    helpText={"Checking this box will require users to fill out this field."}
                />
            </Box>
            <Divider />

            {isRequired == 1 && (
                <Fragment>
                    <Box padding={"400"} paddingBlockStart={"200"}>
                        <TextField
                            label="Error Message"
                            placeholder={"Enter an error message"}
                            value={errorMessage}
                            onChange={(value) => handleChangeStyles('errorMessage', value)}
                            autoComplete="off"
                        />
                    </Box>
                    <Divider />
                </Fragment>
            )}

            {/*<Box padding={"400"} paddingBlockStart={"200"}>*/}
            {/*    <BlockStack gap={"150"}>*/}
            {/*        <Text>Label Alignment</Text>*/}
            {/*        <ButtonGroup variant="segmented" fullWidth>*/}
            {/*            {['left', 'center', 'right'].map((alignment) => (*/}
            {/*                <Button*/}
            {/*                    key={alignment} fullWidth size={"large"}*/}
            {/*                    pressed={style?.alignment === alignment}*/}
            {/*                    onClick={() => handleChangeStyles("alignment", alignment, 'style')}*/}
            {/*                >*/}
            {/*                    {alignment.charAt(0).toUpperCase() + alignment.slice(1)}*/}
            {/*                </Button>*/}
            {/*            ))}*/}
            {/*        </ButtonGroup>*/}
            {/*        <Text tone={"subdued"}>Select how the label text is aligned horizontally</Text>*/}
            {/*    </BlockStack>*/}
            {/*</Box>*/}
        </Fragment>
    );
};

export default FileUploadStyle;