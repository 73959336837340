import React, {Fragment, useContext, useEffect, useState} from 'react';
import {apiService, capitalizeMessage} from "../../Utils/Constent";
import {Box, Button, Card, Divider, IndexTable, InlineStack, Page, Pagination, Text, Tooltip} from "@shopify/polaris";
import moment from "moment/moment";
import {DeleteIcon, ResetIcon} from "@shopify/polaris-icons";
import EllipsisText from "../Common/EllipsisText";
import {workSpaceDetailsAction} from "../../Redux/Action/Action";
import {useDispatch, useSelector} from "react-redux";
import NotFoundContent from "../Common/NotFoundContent";
import {ToastContext} from "../Common/ToastProvider";
import Confirmation from "../Common/Confirmation";

const Trash = () => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);

    const [trashData, setTrashData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [isNextPage, setIsNextPage] = useState(false);
    const [totalPage, setTotalPage] = useState("");
    const [totalRecord, setTotalRecord] = useState(0);
    const [trashFormDeleteRecord, setTrashFormDeleteRecord] = useState({});
    const [loading, setLoading] = useState('');

    const dispatch = useDispatch();
    const workSpacesAll = useSelector((state) => state.workSpaceDetails);

    useEffect(() => {
        getTrash()
    }, [pageNo])

    const getTrash = async () => {
        setIsLoading(true)
        const response = await apiService.getTrash({pageNo});
        if (response.success === true) {
            setIsLoading(false)
            setTrashData(response.data?.forms)
            setIsNextPage(response.data?.nextPage)
            setTotalPage(response.data?.totalPages)
            setTotalRecord(response.data?.totalRecord)
        } else {
            setIsLoading(false)
        }
    }

    const reStore = async (id, workSpaceId) => {
        setSelectedIndex(id)
        const response = await apiService.reStore(id);
        if (response.success === true) {
            setSelectedIndex(null)
            setActive(true);
            setMessage(capitalizeMessage(response?.message));
            const updatedWorkSpaces = (workSpacesAll || []).map(x => {
                if (x.id == workSpaceId) {
                    return {
                        ...x,
                        formCount: x?.formCount + 1,
                    };
                }
                return x;
            });
            dispatch(workSpaceDetailsAction(updatedWorkSpaces));
            let clone = [...trashData]
            const updatedArray = clone.filter((x) => x?.id != id)
            if (updatedArray.length === 0) {
                setPageNo(1);
            } else {
                await getTrash()
            }
        } else {
            setSelectedIndex(null)
            setIsError(true)
            setMessage(response?.error?.message);
        }
    }

    const deleteTrashForm = async () => {
        setLoading('trashForm')
        const response = await apiService.deleteTrashForm(trashFormDeleteRecord?.id);
        if (response.success === true) {
            setLoading('')
            setActive(true);
            setMessage(capitalizeMessage(response?.message));
            getTrash()
            onCloseDeleteModal()
        } else {
            setIsError(true)
            setLoading('')
            setMessage(response?.error?.message);
        }
    }

    const onCloseDeleteModal = () => {
        setTrashFormDeleteRecord({})
    }

    const resourceName = {
        singular: 'Trash',
        plural: 'Trashes',
    };

    const rowMarkup = trashData.map((x, i) => (
            <IndexTable.Row key={i}>
                <IndexTable.Cell>{<EllipsisText text={x.name}/>}</IndexTable.Cell>
                <IndexTable.Cell>{moment(x.deletedAt).format("MMM DD, YYYY")}</IndexTable.Cell>
                <IndexTable.Cell>
                    <Tooltip content={"Restore Form"} dismissOnMouseOut><Button variant={"monochromePlain"} loading={selectedIndex === x.id} icon={ResetIcon} onClick={()=> reStore(x.id, x.workSpaceId)}/></Tooltip>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Tooltip content={"Delete"} dismissOnMouseOut><Button variant={"tertiary"} tone={"critical"} icon={DeleteIcon} onClick={()=> setTrashFormDeleteRecord(x)}/></Tooltip>
                </IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const onPrevious = () => {
        const pageNoNew = Number(pageNo) - 1 === 0 ? 1 : Number(pageNo) - 1
        setPageNo(pageNoNew)
    }

    const onNext = () => {
        setPageNo(Number(pageNo) + 1)
    }

    return (
        <Fragment>
            {
                trashFormDeleteRecord?.id ?
                    <Confirmation
                        title={"Delete Form"} open={trashFormDeleteRecord?.id}
                        onConfirm={deleteTrashForm}
                        isLoading={loading === 'trashForm'}
                        onClose={loading === 'trashForm' ? null : onCloseDeleteModal}
                        message={'Are you sure you want to permanently delete the form?'}
                    /> : null
            }

            <Page title="Trash" subtitle={"Here you can view your deleted forms. These forms will be permanently deleted after 30 days, but you can restore them within this timeframe."}>
                <Card padding={"0"}>
                    <IndexTable
                        emptyState={<NotFoundContent isLoading={isLoading}><Text variant={"headingMd"}>Your trash is currently empty.</Text></NotFoundContent>}
                        resourceName={resourceName}
                        itemCount={isLoading ? null : trashData.length}
                        selectable={false}
                        loading={isLoading}
                        headings={[
                            {title: 'Form Name'},
                            {title: 'Trash Date'},
                            {title: 'Restore'},
                            {title: 'Action'},
                        ]}
                    >
                        {rowMarkup}
                    </IndexTable>
                    {
                        isLoading ? null : trashData?.length > 0 ? <Fragment>
                            <Divider/>
                            <Box padding={"400"}>
                                <InlineStack align={"space-between"} gap={"100"} blockAlign={"center"}>
                                    <Text fontWeight={"medium"}>{totalRecord} Trashes</Text>
                                    <Pagination
                                        hasPrevious={pageNo !== 1}
                                        onPrevious={onPrevious}
                                        hasNext={pageNo < totalPage && isNextPage}
                                        onNext={onNext}
                                    />
                                </InlineStack>
                            </Box>
                        </Fragment> : ""
                    }
                </Card>
            </Page>
        </Fragment>
    );
};

export default Trash;