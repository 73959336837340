import React, {Fragment, useEffect, useState} from 'react';
import {BlockStack, Box, Divider, Button, TextField, Text, ButtonGroup, Checkbox, InlineStack} from "@shopify/polaris";
import {isEmpty, onKeyFire} from "../../../../Utils/Constent";

const VideoStyle = ({ commonProps }) => {
    const { blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles } = commonProps;
    const {style, videoURL, fieldSize} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    const {controlsVideo = "0", autoPlayVideo = "1", muteVideo = "1"} = style;

    const [error, setError] = useState('')
    const [newVideoURL, setNewVideoURL] = useState('')
    const [submitTrue, setSubmitTrue] = useState(false)

    useEffect(() => {
        setNewVideoURL(videoURL)
        setError('')
        if(videoURL?.trim() !== ''){
            setSubmitTrue(true)
        }
    }, [videoURL, selectedPageIndex, selectedBlockIndex])

    useEffect(() => {
        setError('')
    }, [newVideoURL, selectedPageIndex, selectedBlockIndex])

    const onEmbedVideo = () => {
        if(newVideoURL.includes('vimeo.com') || newVideoURL.includes('youtube.com')|| newVideoURL.includes('youtu.be')|| newVideoURL.includes('mp4')){
            handleChangeStyles('videoURL', newVideoURL)
            setSubmitTrue(true)
        } else if(newVideoURL?.trim() === ''){
            setError('Embed link is blanked.');
            setSubmitTrue(false)
        } else {
            setError('Unsupported video platform.');
            setSubmitTrue(false)
        }
    };

    const onRemoveVideo = () => {
        setSubmitTrue(false)
        handleChangeStyles('videoURL', '')
    }

    const handleChangeVideo = (value) => {
        setNewVideoURL(value)
    };

    return (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"150"}>
                    <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, onEmbedVideo)}>
                        <BlockStack gap={"100"}>
                            <InlineStack gap={"100"} align={"space-between"}><Text>Embed Link</Text>
                                {
                                    !isEmpty(newVideoURL) && submitTrue ? <Button onClick={onRemoveVideo} variant={"plain"}>Remove Video</Button> : ""
                                }
                            </InlineStack>
                            <TextField
                                label={'Embed Link'} labelHidden
                                placeholder={"Paste the video link"}
                                value={newVideoURL}
                                onChange={(value) => handleChangeVideo(value)}
                                autoComplete="off"
                                error={error}
                            />
                        </BlockStack>
                    </Box>
                    <Button variant={"primary"} onClick={onEmbedVideo}>Embed Video</Button>
                    <span className="Polaris-Text--root Polaris-Text--break Polaris-Text--subdued">
                        Works with YouTube, Vimeo, MP4s and more
                    </span>
                </BlockStack>
            </Box>

            <Divider />
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"100"}>
                    <Text>Video Settings</Text>
                    <BlockStack>
                        <Checkbox
                            label="Show Controls"
                            checked={controlsVideo == 1}
                            onChange={(checked) => handleChangeStyles('controlsVideo', checked ? "1" : "0", 'style')}
                        />
                        <Checkbox
                            label="Autoplay"
                            checked={autoPlayVideo == 1}
                            onChange={(checked) => handleChangeStyles('autoPlayVideo', checked ? "1" : "0", 'style')}
                        />
                        <Checkbox
                            label="Mute"
                            checked={muteVideo == 1} disabled={autoPlayVideo == 1}
                            onChange={(checked) => handleChangeStyles('muteVideo', checked ? "1" : "0", 'style')}
                        />
                    </BlockStack>
                </BlockStack>
            </Box>

            <Divider />
            <Box padding={"400"} paddingBlockStart={"200"}>
                <BlockStack gap={"100"}>
                    <Text>Block Size</Text>
                    <ButtonGroup variant="segmented" fullWidth>
                        {['medium', 'fullwidth'].map((x) => (
                            <Button
                                key={x} fullWidth size={"large"}
                                pressed={fieldSize === x}
                                onClick={() => handleChangeStyles("fieldSize", x,)}
                            >
                                {x === "fullwidth" ? "Full-width" :  x.charAt(0).toUpperCase() + x.slice(1)}
                            </Button>
                        ))}
                    </ButtonGroup>
                </BlockStack>
            </Box>
            <Divider />
        </Fragment>
    );
};

export default VideoStyle;
