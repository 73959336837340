import { createContext, Fragment, useCallback, useState } from 'react';
import {Frame, Toast} from "@shopify/polaris";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [active, setActive] = useState(false);

    const toggleActive = useCallback(() => {
        setActive(false);
        setIsError(false);
        setMessage('');
    }, []);

    return (
        <Fragment>
            {(active && message !== '') && <div id={"hiddenToastFrame"}><Frame><Toast content={message} onDismiss={toggleActive} error={isError} duration={3000} /></Frame></div>}
            <ToastContext.Provider value={{ setMessage, setIsError, setActive }}>
                {children}
            </ToastContext.Provider>
        </Fragment>
    );
};

export { ToastContext };
