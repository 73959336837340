import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Badge, BlockStack, Box, Button, ButtonGroup, Card, IndexTable, InlineStack, Layout, Page, SkeletonBodyText, Text, Tooltip} from '@shopify/polaris';
import {DateRangePicker} from "../Common/DateRangePicker";
import moment from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {apiService, baseUrl, capitalizeMessage} from "../../Utils/Constent";
import {useDispatch, useSelector} from "react-redux";
import NotFoundContent from "../Common/NotFoundContent";
import EllipsisText from "../Common/EllipsisText";
import {ChartVerticalFilledIcon, DeleteIcon, DuplicateIcon, EditIcon, ShareIcon} from "@shopify/polaris-icons";
import {useNavigate} from "react-router-dom";
import {workSpaceDetailsAction} from "../../Redux/Action/Action";
import Confirmation from "../Common/Confirmation";
import PlanBadge from "../Common/PlanBadge";
import ShareModal from "../MyWorkspace/FormDetails/Share/ShareModal";
import qs from "qs";
import {ToastContext} from "../Common/ToastProvider";

const initialState = {
    completionRate: 0,
    totalFullSubmitted: 0,
    totalPartialSubmitted: 0,
    totalSubmitted: 0,
    totalViews: 0,
}

const Dashboard = () => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);

    const navigate = useNavigate();

    const [stateDate, setStateDate] = useState({startDate: moment().subtract(6, "days").toDate(), endDate: moment().toDate(),});
    const [analyticsData, setAnalyticsData] = useState(initialState);
    const [isLoading, setIsLoading] = useState(true);
    const [chartData, setChartData] = useState({views: [], fullSubmitted: [], partialSubmitted: []})

    const [top5Form, setTop5Form] = useState([]);
    const [forLoading, setForLoading] = useState('');
    const [forSelectedIndex, setForSelectedIndex] = useState(null);
    const [formDeleteRecord, setFormDeleteRecord] = useState({});
    const [isShareModal, setIsShareModal] = useState(false);
    const [formData, setFormData] = useState({});

    const dispatch = useDispatch();
    const planDetails = useSelector((state) => state.planDetails);
    const workSpacesAll = useSelector((state) => state.workSpaceDetails);

    const handleCallback = (dateType) => {
        setStateDate({startDate: dateType.startDate, endDate: dateType.endDate})
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            console.log(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, [])

    useEffect(() => {
        getAnalytics()
    }, [stateDate])

    const getAnalytics = async () => {
        setIsLoading(true)
        const payload = {
            startDate: moment(stateDate.startDate).format("YYYY-MM-DD"),
            endDate: moment(stateDate.endDate).format("YYYY-MM-DD"),
        }
        const response = await apiService.getAnalytics(payload);
        if (response.success === true) {
            setIsLoading(false)
            setAnalyticsData(response?.data)
            const mapData = (data) => data?.map(j => ({ x: new Date(j.x), y: parseInt(j.y) }));
            const partialSubmitted = mapData(response?.data?.partialSubmitted);
            const views = mapData(response?.data?.views);
            const fullSubmitted = mapData(response?.data?.fullSubmitted);
            setChartData({ partialSubmitted, views, fullSubmitted });
            setTop5Form(response.data?.forms);
        } else {
            setIsLoading(false)
        }
    }

    const optionsRender = (data) => {
        return {
            chart: {
                borderWidth: 0,
                type: 'column',
            },
            tooltip: {
                formatter: function () {
                    const date = new Date(this.x);
                    const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
                    return '<span style="font-size: 10px">' + formattedDate + '</span><br>' +
                        '<span style="color:' + this.series.color + '">\u25CF</span> ' + this.series.name + ': <strong>' + this.y + '</strong>';
                }
            },
            title: {text:""},
            yAxis: {
                min: 0,
                title: {
                    text: '',
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    month: '%b %y',
                    year: '%Y'
                },
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.5
                }
            },
            series: data,
            legend: {
                symbolHeight: 15,
                symbolWidth: 15,
                symbolRadius: 5,
                y: 15,
            },
        }
    }

    const submissionsDetail = [
        {label: "Views", value: analyticsData?.totalViews || "0"},
        {label: "Total Submissions", value: analyticsData?.totalSubmitted || "0"},
        {label: "Full Submissions", value: analyticsData?.totalFullSubmitted || "0"},
        {label: "Partial Submissions", value: analyticsData?.totalPartialSubmitted || "0", isPlan: planDetails.planType == 0},
        {label: "Completion Rate", value: `${analyticsData?.completionRate || "0"}%` },
    ]

    const resourceName = {
        singular: 'form',
        plural: 'forms',
    };

    const onEdit = async (record) => {
        navigate(`${baseUrl}/workspace/${record?.workSpaceId}/form/${record?.id}`);
    }

    const onSubmission = (record) => {
        navigate(`${baseUrl}/workspace/${record?.workSpaceId}/submission/${record?.id}`);
    }

    const updateWorkSpaceAll = (sign, wpId) => {
        const updatedWorkSpaces = (workSpacesAll || []).map(x => {
            if (x.id == wpId) {
                return {
                    ...x,
                    formCount: x?.formCount + (sign === '+' ? 1 : sign === '-' ? -1 : 0),
                };
            }
            return x;
        });
        dispatch(workSpaceDetailsAction(updatedWorkSpaces));
    };

    const onShareLink = (record) => {
        setFormData({...record})
        setIsShareModal(true)
    }

    const onDuplicate = async (loader, loadIndex, id, wpId) => {
        const payload = {
            id: id?.toString(),
            workSpaceId: wpId?.toString(),
        }
        setForSelectedIndex(loadIndex)
        setForLoading(loader)
        const response = await apiService.duplicateCreateForm(payload);
        if (response.success === true) {
            setForLoading('')
            setForSelectedIndex(null)
            updateWorkSpaceAll('+', wpId)
            setActive(true)
            setMessage(capitalizeMessage(response?.message));
            await getAnalytics()
        } else {
            setForLoading('')
            setForSelectedIndex(null)
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onDelete = async (record) => {
        setFormDeleteRecord(record)
    }

    const onFormDelete = async () => {
        setForLoading('delete')
        const response = await apiService.deleteForm(formDeleteRecord?.id);
        if (response.success === true) {
            setForLoading('')
            onCloseFormDeleteModal()
            updateWorkSpaceAll('-', formDeleteRecord.workSpaceId)
            setActive(true)
            setMessage("Form Moved to Trash 🚮");
            await getAnalytics()
        } else {
            setForLoading('')
            setFormDeleteRecord({})
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onCloseFormDeleteModal = () => {
        setFormDeleteRecord({})
    }

    const rowMarkup = (top5Form || []).map((x, i) => (
            <IndexTable.Row key={x.id}>
                <IndexTable.Cell>
                    <Button onClick={() => onEdit(x)} variant={"monochromePlain"}>
                        <EllipsisText text={x.name} />
                    </Button>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    {x.publishStatus == 0 ? <Badge tone={"critical"}>Unpublish</Badge> : <Badge tone={"info"}>Publish</Badge>}
                </IndexTable.Cell>
                <IndexTable.Cell>{x.formLayout == 2 ? "Multi-Page" : x.formLayout == 3 ? "HTML" : "One-Page"} Form</IndexTable.Cell>
                <IndexTable.Cell>{x?.formType} </IndexTable.Cell>
                <IndexTable.Cell className="w-200">
                    <ButtonGroup noWrap>
                        <Tooltip dismissOnMouseOut content={"Edit"}><Button icon={EditIcon} onClick={() => onEdit(x)}/></Tooltip>
                        <Tooltip dismissOnMouseOut content={"Share Link"}>
                            <Button icon={ShareIcon} onClick={() => onShareLink(x)} disabled={x.publishStatus == 0}/>
                        </Tooltip>
                        <Tooltip dismissOnMouseOut content={"Submission"}>
                            <Button icon={ChartVerticalFilledIcon} onClick={() => onSubmission(x)}/>
                        </Tooltip>
                        <Tooltip dismissOnMouseOut content={"Duplicate"}>
                            <Button icon={DuplicateIcon} loading={forSelectedIndex === i && forLoading === 'duplicate'}
                                    onClick={() => onDuplicate('duplicate', i, x.id, x.workSpaceId)}/>
                        </Tooltip>
                        <Tooltip dismissOnMouseOut content={"Delete"}>
                            <Button icon={DeleteIcon} loading={forSelectedIndex === i && forLoading === 'delete'}
                                    onClick={() => onDelete(x)}/>
                        </Tooltip>
                    </ButtonGroup>
                </IndexTable.Cell>
            </IndexTable.Row>
        ));

    const commonPropsShare = {
        isShareModal,
        setIsShareModal,
        formData,
        isLoading
    }

    const onWorkSpaceCreate = () => {
        if (workSpacesAll.length > 0) {
            navigate({
                pathname: `${baseUrl}/workspace/${workSpacesAll[0].id}`,
                search: qs.stringify({type: "createFD"}),
            });
        }
    }

    return (
        <Fragment>
            {
                formDeleteRecord?.id ?
                    <Confirmation
                        title={"Remove Form"} open={formDeleteRecord?.id}
                        onConfirm={onFormDelete}
                        isLoading={forLoading === 'delete'}
                        onClose={onCloseFormDeleteModal}
                        message={'Are you sure you want to remove form?'}
                    /> : null
            }

            <ShareModal {...{commonPropsShare}}/>

            <Page title="Dashboard" primaryAction={<DateRangePicker onChange={handleCallback}/>}>
                <Layout>
                    <Layout.Section>
                        <Card padding={"0"}>
                            <div className="dashboard-card">
                                {
                                    submissionsDetail.map((x,i) => {
                                        return (
                                            <Box paddingBlockEnd={"400"} paddingBlockStart={"400"} padding={"500"} key={i}>
                                                <BlockStack gap={isLoading ? "400" : "200"}>
                                                    <Text variant="headingMd" tone={x.isPlan ? "disabled" : ""}>{x.label}</Text>
                                                    {isLoading ? <SkeletonBodyText lines={1} /> : <InlineStack gap={"100"} blockAlign={"center"}><Text variant={"headingLg"} tone={x.isPlan ? "disabled" : ""}>{x.value} </Text> {x.isPlan ? <PlanBadge/>:""}</InlineStack>}
                                                </BlockStack>
                                            </Box>
                                        )
                                    })
                                }
                            </div>
                        </Card>
                    </Layout.Section>
                    <Layout.Section>
                        <Card>
                            {isLoading ? null : (top5Form.length === 0 || (chartData?.views?.length === 0 && chartData?.fullSubmitted?.length === 0 && chartData?.partialSubmitted?.length === 0) ) ?
                                <span className={"no-data"}>
                                    <BlockStack gap={"400"} align={"center"} inlineAlign={"center"}>
                                        <Text fontWeight={"medium"} variant={"bodyLg"} as={"span"}> No submissions received yet</Text>
                                        {
                                            top5Form.length === 0 ?
                                                <Fragment>
                                                    <BlockStack gap={"400"}>
                                                        <Text >You haven't created any forms yet. Get started by creating your first form!</Text>
                                                        <InlineStack align={"center"}><Button onClick={onWorkSpaceCreate}>Create New Form</Button></InlineStack>
                                                    </BlockStack>
                                                </Fragment>
                                                : ""
                                        }
                                    </BlockStack>
                                </span> :""}
                            <HighchartsReact highcharts={Highcharts} options={optionsRender([
                                {
                                    name: "Views",
                                    data: chartData?.views,
                                    color: "#d5ebff",
                                },
                                {
                                    name: "Full Submissions",
                                    data: chartData?.fullSubmitted,
                                    color: "#616161",
                                },
                                {
                                    name: "Partial Submissions",
                                    data: planDetails.planType == 0 ? [] : chartData?.partialSubmitted,
                                    color: "#ffd6a4",
                                    visible: planDetails.planType == 1
                                },
                            ])}/>
                        </Card>
                    </Layout.Section>

                    <Layout.Section>
                        <Card padding={"0"}>
                            <Box padding={"400"}>
                                <Text variant={"headingMd"}>Top 5 Recent Forms</Text>
                            </Box>
                            <IndexTable
                                emptyState={
                                    <NotFoundContent isLoading={isLoading} action={{content: 'Create New Form', onAction : onWorkSpaceCreate}}>
                                <Text variant={"headingMd"}>No  forms available</Text>
                            </NotFoundContent>
                            }
                                resourceName={resourceName}
                                itemCount={isLoading? null : top5Form.length}
                                loading={isLoading}
                                selectable={false}
                                headings={[
                                    {title: 'Title'},
                                    {title: 'Status'},
                                    {title: 'Form Layout'},
                                    {title: 'Form Type'},
                                    {title: 'Action'},
                                ]}
                            >
                                {rowMarkup}
                            </IndexTable>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        </Fragment>
    );
};

export default Dashboard;