import React, {Fragment, useContext, useEffect, useState} from 'react';
import {BlockStack, Box, Button, InlineStack, Select, Text, TextField} from "@shopify/polaris";
import {Icons} from "../../Utils/Icons";
import {apiService, baseUrl, getTokenVerify} from "../../Utils/Constent";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "qs";
import ThankYouBoarding from "./ThankYouBoarding";
import {useSelector} from "react-redux";
import {ToastContext} from "../Common/ToastProvider";

const initialState = {
    industry: "",
    responsibility: "",
    specifyIndustry: "",
    specifyResponsibility: "",
    aboutWebForm: "",
};

const OnBoarding = () => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);

    const navigate = useNavigate();
    let location = useLocation();
    const UrlParams = new URLSearchParams(location.search);
    const stepType = UrlParams.get("step") || 1;
    const [step, setStep] = useState(Number(stepType) <= 3 ? Number(stepType) : 1);
    const [boardingDetails, setBoardingDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const planDetails = useSelector((state) => state.planDetails);
    const workSpacesAll = useSelector((state) => state.workSpaceDetails);

    useEffect(() => {
        document.title = 'WebForm | Onboarding';
    }, []);

    useEffect(() => {
        if(planDetails.boarding == 1){
            navigate(`${baseUrl}/dashboard`);
        }
    }, [planDetails])

    useEffect(() => {
        navigate({
            pathname: `${baseUrl}/onboarding`,
            search: qs.stringify({step}),
        });
    }, [step])

    window.onpopstate = () => onBack();
    const onBack = () => {
        if(step > 1 || step < 3){
            setStep(step - 1)
        }
    }

    const formValidate = (name, value) => {
        switch (name) {
            case "industry":
                if (!value.trim()) {
                    return "This field is required.";
                } else {
                    return "";
                }
            case "responsibility":
                if (!value.trim()) {
                    return "This field is required.";
                } else {
                    return "";
                }
            case "specifyIndustry":
                if (!value.trim() && boardingDetails.industry === 'Other(Please specify)') {
                    return "This field is required.";
                } else {
                    return "";
                }
            case "specifyResponsibility":
                if (!value.trim() && boardingDetails.responsibility === 'Other (please specify)') {
                    return "This field is required.";
                } else {
                    return "";
                }
            case "aboutWebForm":
                if (!value.trim()) {
                    return "This field is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onWorkSpaceCreate = () => {
        if (workSpacesAll.length > 0) {
            navigate({
                pathname: `${baseUrl}/workspace/${workSpacesAll[0].id}`,
                search: qs.stringify({type: "boarding"}),
            });
        } else {
            navigate(`${baseUrl}/dashboard`);
        }
    }

    const onStep = async (stepType, type = '') => {
        if (stepType < 3) {
            setStep(stepType)
        }
        if (stepType === 3) {
            let errorData = {};
            Object.keys(boardingDetails).map((x) => {
                let error = formValidate(x, boardingDetails[x]);
                if (error && error.length > 0) {
                    errorData[x] = error;
                }
            });
            if (Object.keys(errorData).length > 0) {
                setErrors(errorData);
                return;
            }

            setIsLoading(true)
            const payload = new FormData();
            payload.append('industry', boardingDetails.industry);
            payload.append('responsibility', boardingDetails.responsibility);
            payload.append('specifyIndustry', boardingDetails.specifyIndustry);
            payload.append('specifyResponsibility', boardingDetails.specifyResponsibility);
            payload.append('aboutWebForm', boardingDetails.aboutWebForm);
            payload.append('boarding', '1');
            const token = getTokenVerify("token-verify-onboarding")
            const response = await apiService.updateProfile(payload, {Authorization: `Bearer ${token}`});
            if (response.success === true) {
                setIsLoading(false)
                setStep(stepType)
                setActive(true);
                setMessage("🚀 You've completed onboarding! Ready to explore!");
                localStorage.setItem("token", token);
                localStorage.removeItem("token-verify-onboarding");
            } else {
                setIsLoading(false)
                setActive(true);
                setMessage(response?.error?.message);
                setIsError(true);
            }
        } else if (stepType === 4) {
            if (type === 'create') {
                onWorkSpaceCreate()
            } else if (type === 'dashboard') {
                navigate(`${baseUrl}/dashboard`);
            }
        }
    }

    const handleSelectChange = (name ,value) => {
       setBoardingDetails({...boardingDetails, [name] : value})
        setErrors((formError) => ({
            ...formError,
            [name]: formValidate(name, value),
        }));
    }

    const optionsIndustry = [
        {label: 'Engineering & Technology', value: 'Engineering & Technology'},
        {label: 'Creative & Design', value: 'Creative & Design'},
        {label: 'Sales & Business Development', value: 'Sales & Business Development'},
        {label: 'Marketing & Communications', value: 'Marketing & Communications'},
        {label: 'Customer Support & Success', value: 'Customer Support & Success'},
        {label: 'Operations & Logistics', value: 'Operations & Logistics'},
        {label: 'Human Resources & People Management', value: 'Human Resources & People Management'},
        {label: 'Information Technology & Software', value: 'Information Technology & Software'},
        {label: 'Finance & Accounting', value: 'Finance & Accounting'},
        {label: 'Healthcare & Wellness', value: 'Healthcare & Wellness'},
        {label: 'Other', value: 'Other(Please specify)'},
    ];

    const optionsResponsibility = [
        {label: 'Business Owner', value: 'Business Owner'},
        {label: 'Freelancer', value: 'Freelancer'},
        {label: 'Team Lead', value: 'Team Lead'},
        {label: 'Product Manager', value: 'Product Manager'},
        {label: 'Department lead', value: 'Department lead'},
        {label: 'Team manager', value: 'Team manager'},
        {label: 'Team member', value: 'Team member'},
        {label: 'Using for myself', value: 'Using for myself'},
        {label: 'Other', value: 'Other (please specify)'},
    ];

    const optionsWebForm = [
        {label: 'Blog article', value: 'Blog article'},
        {label: 'Search engine', value: 'Search engine'},
        {label: 'Facebook post', value: 'Facebook post'},
        {label: 'Recommendation from a friend or colleague', value: 'Recommendation from a friend or colleague'},
        {label: 'You tube video', value: 'You tube video'},
        {label: 'Reddit discussion', value: 'Reddit discussion'},
        {label: 'LinkedIn post', value: 'LinkedIn post'},
        {label: 'Twitter mention', value: 'Twitter mention'},
        {label: 'Product Hunt feature', value: 'Product Hunt feature'},
        {label: 'Email newsletter', value: 'Email newsletter'},
        {label: 'Other', value: 'Other'},
    ];

    return (
        <Fragment>
            <div className="on-boarding">
                <div className="">
                    <Box maxWidth={step !== 3 ? "606px" : "658px"} padding={"400"}>
                        <InlineStack align={"center"}><Box paddingBlockEnd={"1200"}>{Icons.logoBlack}</Box></InlineStack>

                        {
                            step === 1 &&
                            <Fragment>
                                <BlockStack as={"span"} gap={"600"} align={"center"} inlineAlign={"center"}>
                                    <BlockStack as={"span"} gap={"200"} inlineAlign={"center"}>
                                        <Text variant={"heading2xl"}><Text as={"span"} variant={"heading2xl"}>👋</Text>Welcome</Text>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                            Welcome to Webform, your go-to tool for creating dynamic forms with unlimited
                                            possibilities.
                                        </Text>
                                    </BlockStack>
                                    <Box>
                                        <Button variant={"primary"} size={"large"} onClick={()=> onStep(2)}>Let’s get started</Button>
                                    </Box>
                                </BlockStack>
                            </Fragment>
                        }

                        {
                            step === 2 &&
                            <Fragment>
                                <BlockStack as={"span"} gap={"600"} align={"center"} inlineAlign={"center"}>
                                    <BlockStack as={"span"} gap={"200"}>
                                        <Text variant={"heading2xl"}>We’d Like to Get to Know You</Text>
                                        <Text alignment={"center"} variant={"headingMd"} fontWeight={"regular"}>
                                            Tell us a bit about yourself! We’d love to get to know you better.
                                        </Text>
                                    </BlockStack>

                                    <Box minWidth={"70%"}>
                                        <BlockStack as={"span"} gap={"600"}>
                                            <Select
                                                label="What industry do you work in?"
                                                options={[{label: "Select industry", value: "", disabled: true}, ...optionsIndustry]}
                                                onChange={(val) => handleSelectChange("industry", val)}
                                                value={boardingDetails.industry} error={formError.industry}
                                            />

                                            {
                                                boardingDetails.industry === "Other(Please specify)" &&
                                                <TextField
                                                    label="Please Specify" error={formError.specifyIndustry}
                                                    onChange={(val) => handleSelectChange("specifyIndustry", val)}
                                                    value={boardingDetails.specifyIndustry}
                                                />
                                            }

                                            <Select
                                                label="What’s your current role or responsibility?"
                                                options={[{label: "Select role or responsibility", value: "", disabled: true}, ...optionsResponsibility]}
                                                onChange={(val) => handleSelectChange("responsibility", val)}
                                                value={boardingDetails.responsibility} error={formError.responsibility}
                                            />

                                            {
                                                boardingDetails.responsibility === "Other (please specify)" &&
                                                <TextField
                                                    label="Please Specify" error={formError.specifyResponsibility}
                                                    onChange={(val) => handleSelectChange("specifyResponsibility", val)}
                                                    value={boardingDetails.specifyResponsibility}
                                                />
                                            }

                                            <Select
                                                label="How did you get to know about WebForm?"
                                                options={[{label: "Select about WebForm", value: "", disabled: true}, ...optionsWebForm]}
                                                onChange={(val) => handleSelectChange("aboutWebForm", val)}
                                                value={boardingDetails.aboutWebForm} error={formError.aboutWebForm}
                                            />
                                        </BlockStack>
                                    </Box>
                                    <Box>
                                        <Button variant={"primary"} size={"large"} onClick={()=> onStep(3)} loading={isLoading}>Continue</Button>
                                    </Box>
                                </BlockStack>
                            </Fragment>
                        }

                        {step === 3 && <ThankYouBoarding {...{onStep, onWorkSpaceCreate}}/>}
                    </Box>
                </div>
            </div>
        </Fragment>
    );
};

export default OnBoarding;