import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {BlockStack, Box, Button, Checkbox, Icon, InlineStack, Text, TextField} from "@shopify/polaris";
import {apiService, baseUrl, getTokenVerify, onKeyFire} from "../../Utils/Constent";
import AuthFrame from "../AuthStructure/AuthFrame";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUserDetails} from "../../Redux/Action/Action";
import {ToastContext} from "../../Components/Common/ToastProvider";
import {ArrowLeftIcon} from "@shopify/polaris-icons";

const initialState = {
    otp: "",
    remember: false,
};

const Tfa = () => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);

    const navigate = useNavigate();
    const [authDetails, setAuthDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'WebForm | 2FA (Two-Factor Authentication)';
    }, []);

    const formValidate = (name, value) => {
        switch (name) {
            case "otp":
                if (!value || value.trim() === "") {
                    return "Code is required.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
            setAuthDetails(prevTokenDetails => ({...prevTokenDetails, [name]: value}));
            setErrors(formError => ({...formError, [name]: '',}));
        },
        [authDetails],
    );

    const onBlurChange = useCallback((name, value) => {
            setErrors(formError => ({
                ...formError,
                [name]: formValidate(name, value),
            }));
        },
        [authDetails],
    );

    const handleTFAContinue = async () => {
        let errorData = {};
        Object.keys(authDetails).map((x) => {
            let error = formValidate(x, authDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setIsLoading(true);
        const payload = {otp: authDetails.otp, remember: authDetails.remember};

        const token = getTokenVerify("token-verify-2fa")
        const response = await apiService.verify2fa(payload, {Authorization: `Bearer ${token}`});
        if (response.success === true) {
            setIsLoading(false);
            setMessage("You’re logged in! 🚀");
            setActive(true);
            setAuthDetails(initialState);
            localStorage.setItem("2FA", "false");
            setIsError(false)
            localStorage.removeItem("token-verify-2fa");
            localStorage.setItem("token", response?.data?.token);
            dispatch(setUserDetails(response?.data));
            navigate(`${baseUrl}/dashboard`);
        } else {
            setIsLoading(false);
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    };

    return (
        <Fragment>
            <AuthFrame title={"Two-factor Authentication"}>
                <Box as={"span"} width={"100%"}>
                    <BlockStack gap={"400"}>
                        <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, handleTFAContinue)}>
                            <TextField label={"Enter an authenticator app code or a recovery code"} placeholder={"Code"} value={authDetails.otp} type={"text"}
                                       error={formError.otp} onBlur={(e) => onBlurChange("otp", e.target.value)}
                                       onChange={(value) => onHandleChange("otp", value)}
                            />
                            <Box paddingBlockStart={"100"}>
                                <InlineStack align={"start"} as={"span"}>
                                    <Checkbox label={<Text tone={"subdued"} as={"span"}>Remember me for 30 days for this browser</Text>} checked={authDetails.remember} onChange={(checked) => onHandleChange("remember", checked)}/>
                                </InlineStack>
                            </Box>
                        </Box>
                        <Button variant={"primary"} size={"large"} loading={isLoading} onClick={handleTFAContinue}>Continue</Button>

                        <button className={"back-log-btn"} onClick={() => navigate(`${baseUrl}/login`)}>
                            <InlineStack blockAlign={"center"} gap={"150"} as={"span"}>
                                <Icon source={ArrowLeftIcon}/>
                                <Text as={"span"} fontWeight={"medium"}>Return to login</Text>
                            </InlineStack>
                        </button>
                    </BlockStack>
                </Box>
            </AuthFrame>
        </Fragment>
    );
};

export default Tfa;