import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import AuthFrame from "../AuthStructure/AuthFrame";
import {BlockStack, Button, Text, TextField, Box} from "@shopify/polaris";
import {useNavigate} from "react-router-dom";
import {apiService, baseUrl, emailRegExp, onKeyFire, OR} from "../../Utils/Constent";
import {HideIcon, ViewIcon} from "@shopify/polaris-icons";
import WithGoogle from "./WithGoogle";
import {setUserDetails} from "../../Redux/Action/Action";
import {useDispatch} from "react-redux";
import {ToastContext} from "../../Components/Common/ToastProvider";

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
};

const Register = () => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);

    const navigate = useNavigate();
    const [authDetails, setAuthDetails] = useState(initialState);
    const [formError, setErrors] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [isShowPaas, setIsShowPaas] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'WebForm | Register';
    }, []);

    const formValidate = (name, value) => {
        switch (name) {
            case "firstName":
                if (!value || value.trim() === "") {
                    return "First name is required";
                } else {
                    return "";
                }
            case "lastName":
                if (!value || value.trim() === "") {
                    return "Last name is required";
                } else {
                    return "";
                }
            case "email":
                if (!value.trim()) {
                    return "Email is required.";
                } else if (!value.match(emailRegExp)) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            case "password":
                if (!value || value.trim() === "") {
                    return "Password is required";
                } else if (value.length < 8) {
                    return "Password must be 8 character."
                }
                else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onHandleChange = useCallback((name, value) => {
            setAuthDetails(prevTokenDetails => ({
                ...prevTokenDetails,
                [name]: value
            }));
            setErrors(formError => ({...formError, [name]: '',}));
        },
        [authDetails],
    );

    const onBlurChange = useCallback((name, value) => {
            setErrors(formError => ({
                ...formError,
                [name]: formValidate(name, value),
            }));
        },
        [authDetails],
    );

    const onRegister = async () => {
        let errorData = {};
        Object.keys(authDetails).map((x) => {
            let error = formValidate(x, authDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }
        setIsLoading(true);

        const payload = {
            ...authDetails
        };

        const response = await apiService.register(payload);
        if (response.success === true) {
            setIsLoading(false);
            setMessage("Welcome! Your account is ready to go. 🚀 Let's get started!");
            setActive(true);
            setAuthDetails(initialState);
            setIsError(false)
            localStorage.setItem("token-verify-onboarding", response?.data?.token);
            dispatch(setUserDetails(response?.data));
            let userDetails = {...response?.data};
            delete userDetails?.token;
            localStorage.setItem("user-details", JSON.stringify(userDetails));
            navigate(`${baseUrl}/onboarding`);
        } else {
            setIsLoading(false);
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onNavigate = (url) => {
        navigate(`${baseUrl}/${url}`)
    }

    return (
        <Fragment>
            <AuthFrame title={"Welcome 👋"}
                       subTitle={<Text as={"span"} tone={"subdued"}>Or <button className={"create-btn"} onClick={()=>onNavigate('login')}>Sign in to your account</button></Text>}
            >
                <Box as={"span"} width={"100%"}>
                    <BlockStack gap={"300"}>
                        <Box as={"span"} width={"100%"}>
                            <TextField
                                label={"First Name"}
                                placeholder={"First name"}
                                value={authDetails.firstName}
                                error={formError.firstName}
                                onBlur={(e) => onBlurChange("firstName", e.target.value)}
                                onChange={(value) => onHandleChange("firstName", value)}
                            />
                        </Box>
                        <Box as={"span"} width={"100%"}>
                            <TextField
                                placeholder={"Last name"}
                                label={"Last Name"}
                                value={authDetails.lastName}
                                error={formError.lastName}
                                onBlur={(e) => onBlurChange("lastName", e.target.value)}
                                onChange={(value) => onHandleChange("lastName", value)}
                            />
                        </Box>
                        <Box as={"span"} width={"100%"}>
                            <TextField
                                label={"Email"}
                                placeholder={"Email"} type={"email"}
                                value={authDetails.email}
                                error={formError.email}
                                onBlur={(e) => onBlurChange("email", e.target.value)}
                                onChange={(value) => onHandleChange("email", value)}
                            />
                        </Box>
                        <Box as={"span"} width={"100%"} onKeyPress={(e) => onKeyFire(e, onRegister)}>
                            <TextField
                                label={"Password"}
                                placeholder={"Create a password"}
                                value={authDetails.password} type={isShowPaas ? "text" : "password"}
                                error={formError.password} suffix={
                                <Box paddingBlockStart={"150"}><Button icon={!isShowPaas ? ViewIcon : HideIcon} variant={"plain"} onClick={()=>setIsShowPaas(!isShowPaas)}/></Box>}
                                onBlur={(e) => onBlurChange("password", e.target.value)}
                                onChange={(value) => onHandleChange("password", value)}
                            />
                        </Box>
                        <Box as={"span"}/>
                        <Button size={"large"} variant={"primary"} loading={isLoading} onClick={isGoogleLoading ? null : onRegister}>Register</Button>
                        <Fragment>{OR}</Fragment>
                        <WithGoogle {...{text: 'Signup with Google',isLoading, isGoogleLoading, setIsGoogleLoading, register :true}} />
                    </BlockStack>
                </Box>
            </AuthFrame>
        </Fragment>
    );
};

export default Register;