import React, {Fragment} from 'react';
import {Modal, Text} from "@shopify/polaris";

const AccountInformation = ({isConfirmUpdate,onCancelChange,handleUpdate,loading,}) => {
    return (
        <Fragment>
            <Modal
                open={isConfirmUpdate}
                onClose={onCancelChange}
                title="Account Information"
                primaryAction={{
                    content: 'Yes',
                    onAction: handleUpdate,
                    loading: loading === 'updateProfile'
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: onCancelChange,
                    },
                ]}
            >
                <Modal.Section>
                    <Text>
                        Are you sure you want to update?
                    </Text>
                </Modal.Section>
            </Modal>
        </Fragment>
    );
};

export default AccountInformation;