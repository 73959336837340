import React, {Fragment, useCallback, useContext, useState} from 'react';
import {BlockStack, Box, Button, Modal, TextField} from "@shopify/polaris";
import {apiService, getLSUserDetails} from "../../../Utils/Constent";
import {ToastContext} from "../../Common/ToastProvider";
import {HideIcon, ViewIcon} from "@shopify/polaris-icons";
import {setUserDetails} from "../../../Redux/Action/Action";
import {useDispatch} from "react-redux";

const initialStatePass = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    password: '',
};

const PasswordFactory = ({isChangePassword, setIsChangePassword, profileDetails,setProfileDetails, setOldProfileDetails}) => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);

    const [formError, setErrors] = useState(initialStatePass);
    const [passDetails, setPassDetails] = useState(initialStatePass);
    const [isLoader, setLoader] = useState(false);
    const [isShowNewPass, setIsShowNewPass] = useState(false);
    const dispatch = useDispatch();

    const formValidate = (name, value) => {
        switch (name) {
            case "oldPassword":
                if (((!profileDetails?.isGoogleConnect && profileDetails?.isPasswordCreated) || (profileDetails?.isGoogleConnect && profileDetails?.isPasswordCreated))) {
                    if (!value.trim()) {
                        return "Password is required.";
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            case "newPassword":
                if (((!profileDetails?.isGoogleConnect && profileDetails?.isPasswordCreated)) || (profileDetails?.isGoogleConnect && profileDetails?.isPasswordCreated)) {
                    if (!value.trim()) {
                        return "New password is required.";
                    } else if (value.length < 8) {
                        return "New password must be at least 8 characters.";
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            case "confirmPassword":
                if (((!profileDetails?.isGoogleConnect && profileDetails?.isPasswordCreated)) || (profileDetails?.isGoogleConnect && profileDetails?.isPasswordCreated)) {
                    if (value !== passDetails.newPassword) {
                        return "Confirm password doesn't match new password.";
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            case "password":
                if (profileDetails?.isGoogleConnect && !profileDetails?.isPasswordCreated) {
                    if (!value.trim()) {
                        return "Password is required";
                    } else if (value.length < 8) {
                        return "Password must be at least 8 characters.";
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    const onPassword = async (type) => {
        let errorData = {};
        Object.keys(passDetails).map((x) => {
            let error = formValidate(x, passDetails[x]);
            if (error && error.length > 0) {
                errorData[x] = error;
            }
        });
        if (Object.keys(errorData).length > 0) {
            setErrors(errorData);
            return;
        }

        let payload
        if (type === 'Change') {
            payload = {
                oldPassword: passDetails.oldPassword,
                newPassword: passDetails.newPassword
            }
        } else {
            payload = {
                password: passDetails.password
            }
        }
        setLoader(true);
        let response;
        if (type === 'Change') {
            response = await apiService.updatePassword(payload);
        } else {
            response = await apiService.createPassword(payload);
        }
        if (response.success === true) {
            setLoader(false);
            dispatch(setUserDetails(response?.data));
            const user = {...getLSUserDetails(), ...response.data,};
            localStorage.setItem("user-details", JSON.stringify(user));
            dispatch(setUserDetails(user));
            setProfileDetails({...response?.data});
            setOldProfileDetails({...response?.data});
            setActive(true);
            setIsError(false)
            if (type === 'Change') {
                setMessage("Password Updated Successfully! 🔐");
            } else {
                setMessage("Password Created! You’re All Set! 🎉");
            }
            onCloseModal()
        } else {
            setLoader(false);
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onCloseModal = () => {
        setIsChangePassword(false)
        setPassDetails(initialStatePass)
        setErrors(initialStatePass)
    }

    const onHandleChange = useCallback((name, value) => {
        setPassDetails((prev) => ({...prev, [name]: value,}));
        setErrors(formError => ({...formError, [name]: '',}));
    }, [passDetails]);

    const onBlurChange = useCallback((name, value) => {
            setErrors(formError => ({
                ...formError,
                [name]: formValidate(name, value),
            }));
        },
        [passDetails],
    );

    return (
        <Fragment>
            <Modal open={isChangePassword} onClose={isLoader ? null : onCloseModal} size={"small"}
                title={`${(profileDetails?.isGoogleConnect && !profileDetails?.isPasswordCreated) ? "Set" : "Change"}  Password`}
                primaryAction={{
                    content: 'Save',
                    onAction: () => onPassword((profileDetails?.isGoogleConnect && !profileDetails?.isPasswordCreated) ?  "Set" : "Change"),
                    loading: isLoader
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: isLoader ? null : onCloseModal,
                    },
                ]}
            >
                <Modal.Section>
                    <BlockStack as={"span"} gap={"400"}>
                        {
                            (profileDetails?.isGoogleConnect && !profileDetails?.isPasswordCreated) ?
                                <TextField
                                    label="Password" type={isShowNewPass ? "text" : "password"}
                                    placeholder={"Enter password"}
                                    value={passDetails.password}
                                    error={formError.password}
                                    onChange={(value) => onHandleChange("password", value)}
                                    onBlur={(e) => onBlurChange("password", e.target.value)}
                                    suffix={
                                        <Box paddingBlockStart={"100"}><Button icon={!isShowNewPass ? ViewIcon : HideIcon} variant={"plain"} onClick={()=> setIsShowNewPass(!isShowNewPass)}/></Box>
                                    }
                                />:
                            <Fragment>
                                <TextField
                                    label="Old password" type={"password"}
                                    placeholder={"Enter old password"}
                                    value={passDetails.oldPassword}
                                    error={formError.oldPassword}
                                    onChange={(value) => onHandleChange("oldPassword", value)}
                                    onBlur={(e) => onBlurChange("oldPassword", e.target.value)}
                                />
                                <TextField
                                    label="New password" type={"password"}
                                    placeholder={"Enter new password"}
                                    value={passDetails.newPassword}
                                    error={formError.newPassword}
                                    onChange={(value) => onHandleChange("newPassword", value)}
                                    onBlur={(e) => onBlurChange("newPassword", e.target.value)}
                                />
                                <TextField
                                    label="Confirm new password" type={"password"}
                                    placeholder={"Enter password"}
                                    value={passDetails.confirmPassword}
                                    error={formError.confirmPassword}
                                    onBlur={(e) => onBlurChange("confirmPassword", e.target.value)}
                                    onChange={(value) => onHandleChange("confirmPassword", value)}
                                />
                            </Fragment>
                        }
                    </BlockStack>
                </Modal.Section>
            </Modal>
        </Fragment>
    );
};

export default PasswordFactory;