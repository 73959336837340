import React, {Fragment} from 'react';
import {Box, Button, Divider, TextField} from "@shopify/polaris";

const CaptchaKey = ({commonProps}) => {
    const { blockArea, selectedPageIndex, selectedBlockIndex, handleChangeStyles } = commonProps;
    const {captchaKey} = blockArea[selectedPageIndex]?.[selectedBlockIndex] || {};

    return (
        <Fragment>
            <Box padding={"400"} paddingBlockStart={"200"}>
                <TextField
                    label="Captcha Key"
                    placeholder={"Enter captcha key here"}
                    value={captchaKey}
                    onChange={(value) => handleChangeStyles('captchaKey', value)}
                    helpText={<Fragment>
                        Works with site key, <Button variant={"plain"} external url={"https://cloud.google.com/security/products/recaptcha?hl=en"}>learn</Button> how to set up your reCAPTCHA account here.
                    </Fragment>}
                    autoComplete="off"
                />
            </Box>
            <Divider/>
        </Fragment>
    );
};

export default CaptchaKey;