import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Button, InlineStack} from "@shopify/polaris";
import {Icons} from "../../Utils/Icons";
import {loadAuth2, loadGapiInsideDOM} from "gapi-script";
import {apiService, baseUrl, getToken, googleClientId} from "../../Utils/Constent";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setUserDetails} from "../../Redux/Action/Action";
import {ToastContext} from "../../Components/Common/ToastProvider";
import {detectIncognito} from "detectincognitojs";

const WithGoogle = ({text, isLoading, isGoogleLoading, setIsGoogleLoading, register}) => {
    const { setMessage, setIsError, setActive } = useContext(ToastContext);
    const navigate = useNavigate();
    const [gapi, setGapi] = useState(null);
    const dispatch = useDispatch();
    const [isPrivate, setIsPrivate] = useState(false);

    useEffect(() => {
        detectIncognito().then((result) => {
            setIsPrivate(result.isPrivate);
        });
    }, []);

    useEffect(() => {
        if (!getToken()) {
            const loadGapi = async () => {
                const newGapi = await loadGapiInsideDOM();
                setGapi(newGapi);
            }
            loadGapi();
        }
    }, [getToken()]);

    const handleGoogleLogin = async () => {
        try {
            const auth2 = await loadAuth2(gapi, googleClientId, "profile");
            const googleUser = await auth2.signIn();
            updateUser(googleUser);
        } catch (error) {
            console.error('Google sign-in error:', error);
        }
    };

    const updateUser = async (currentUser) => {
        // const name = currentUser.getBasicProfile().getName();
        const getEmail = currentUser.getBasicProfile().getEmail();
        let firstName = currentUser.getBasicProfile().getGivenName() ;
        let lastName = currentUser.getBasicProfile().getFamilyName() ;
        let userId = currentUser.getBasicProfile().getId() ;
        const profileImg = currentUser.getBasicProfile().getImageUrl();

        const payload = {
            firstName: firstName,
            lastName: lastName,
            email: getEmail,
            googleId: userId,
            profileImage: profileImg,
            isPrivate: isPrivate,
        }
        setIsGoogleLoading(true);
        const response = await apiService.loginWithGoogle(payload);
        if (response.success === true) {
            setIsGoogleLoading(false);
            setActive(true);
            setIsError(false);
            dispatch(setUserDetails(response?.data));
            let userDetails = { ...response?.data };
            delete userDetails?.token;
            localStorage.setItem("user-details", JSON.stringify(userDetails));
            if(response?.data?.boarding == 0){
                setMessage(register? "Welcome! Your account is ready to go. 🚀 Let's get started!" : "You’re logged in! 🚀");
                localStorage.setItem("token-verify-onboarding", response?.data?.token);
                navigate(`${baseUrl}/onboarding`);
            } else {
                if (response?.data?.enabled2fa == 1) {
                    localStorage.setItem("token-verify-2fa", response?.data?.token);
                    navigate(`${baseUrl}/verify-2fa`);
                } else {
                    setMessage(register? "Welcome! Your account is ready to go. 🚀 Let's get started!" : "You’re logged in! 🚀");
                    localStorage.setItem("token", response?.data?.token);
                    navigate(`${baseUrl}/dashboard`);
                }
            }
        } else {
            setIsGoogleLoading(false);
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
            signOut();
        }
    };

    const signOut = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
            console.log('User signed out.');
        });
    }

    return (
        <Fragment>
            <Button size="large" onClick={isLoading ? null : handleGoogleLogin} loading={isGoogleLoading}>
                <InlineStack gap={200}>{isGoogleLoading ? null : Icons.google} {text}</InlineStack>
            </Button>
        </Fragment>
    );
};

export default WithGoogle;