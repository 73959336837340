import React, {Fragment} from 'react';
import {Box, Button, InlineStack, Modal, Text, TextField} from "@shopify/polaris";
import {Icons} from "../../../Utils/Icons";
import {HideIcon, ViewIcon} from "@shopify/polaris-icons";

const TwoFactor = ({modalType,onCancelModal, onModalHit, loading, authPassword, authPasswordError, isShowPaas, onChangeAuth, setIsShowPaas, profileDetails}) => {
    return (
        <Fragment>
            <Modal
                open={modalType === 'authPass2FA'}
                onClose={onCancelModal}
                title={<InlineStack gap={"200"} blockAlign={"center"} as={"span"}>{Icons.authKey}<Text as={"span"}>2FA (Two-Factor Authentication)</Text></InlineStack>}
                primaryAction={{
                    content: 'Save',
                    onAction: () => onModalHit("auth", profileDetails.enabled2fa == 1 ? '2FAD' : '2FAE'),
                    loading: loading === 'auth'
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: onCancelModal,
                    },
                ]}
            >
                <Modal.Section>
                    <TextField
                        label="Password"
                        placeholder={"Enter password"}
                        value={authPassword} type={isShowPaas ? "text" : "password"}
                        error={authPasswordError}
                        onChange={(value) => onChangeAuth(value)}
                        suffix={
                            <Box paddingBlockStart={"100"}><Button icon={!isShowPaas ? ViewIcon : HideIcon} variant={"plain"} onClick={()=> setIsShowPaas(!isShowPaas)}/></Box>
                        }
                    />
                </Modal.Section>
            </Modal>
        </Fragment>
    );
};

export default TwoFactor;