import React,{useState, useEffect, useRef, } from "react";
import {useBreakpoints, Button, Popover, Divider, Box,ButtonGroup, OptionList, Select, DatePicker,Card, InlineStack, InlineGrid} from "@shopify/polaris";
import {CalendarIcon} from "@shopify/polaris-icons";
import moment from "moment";

export function DateRangePicker({ onChange}) {
    const {smDown, mdDown, lgUp} = useBreakpoints();
    const shouldShowMultiMonth = lgUp;

    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const currentMonthDate = new Date();
    currentMonthDate.setMonth(currentMonthDate.getMonth() + 3);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    let ranges = [
        {
            title: "Today",
            alias: "today",
            period: {
                since: moment().toDate(),
                until: moment().toDate(),
            },
        },
        {
            title: "Yesterday",
            alias: "yesterday",
            period: {
                since: moment().subtract(1, "days").toDate(),
                until: moment().subtract(1, "days").toDate(),
            },
        },
        {
            title: "Last 7 days",
            alias: "last7days",
            period: {
                since: moment().subtract(6, "days").toDate(),
                until: moment().toDate(),
            },
        },
        {
            title: "Last 30 Days",
            alias: "last30days",
            period: {
                since: moment().subtract(29, "days").toDate(),
                until: moment().toDate()
            },
        },
        {
            title: "Last 90 Days",
            alias: "last90days",
            period: {
                since: moment().subtract(89, "days").toDate(),
                until: moment().toDate()
            },
        },
        {
            title: "Last 365 Days",
            alias: "last365days",
            period: {
                since: moment().subtract(364, "days").toDate(),
                until: moment().toDate()
            },
        },
        {
            title: "Last Month",
            alias: "lastMonth",
            period: {
                since: moment().subtract(1, "month").startOf("month").toDate(),
                until: moment().subtract(1, "month").endOf("month").toDate(),
            },
        },
        {
            title: "Last 6 Months",
            alias: "last6Months",
            period: {
                since: moment().subtract(6, "months").startOf("month").toDate(),
                until: moment().toDate(),
            },
        },
        {
            title: "Last Year",
            alias: "lastYear",
            period: {
                since: moment().subtract(1, "year").startOf("year").toDate(),
                until: moment().subtract(1, "year").endOf("year").toDate(),
            },
        }
    ]

    const getRange = ranges[2];
    const [popoverActive, setPopoverActive] = useState(false);
    const [activeDateRange, setActiveDateRange] = useState(getRange);
    // const [inputValues, setInputValues] = useState({});
    const [{ month, year }, setDate] = useState({
        month: activeDateRange.period.since.getMonth(),
        year: activeDateRange.period.since.getFullYear(),
    });

    const datePickerRef = useRef(null);
    // const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/;
    //
    // function isDate(date) {
    //     return !isNaN(new Date(date).getDate());
    // }
    // function isValidYearMonthDayDateString(date) {
    //     return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date);
    // }
    // function isValidDate(date) {
    //     return date.length === 10 && isValidYearMonthDayDateString(date);
    // }
    //
    // function parseYearMonthDayDateString(input) {
    //     // Date-only strings (e.g. "1970-01-01") are treated as UTC, not local time
    //     // when using new Date()
    //     // We need to split year, month, day to pass into new Date() separately
    //     // to get a localized Date
    //     const [year, month, day] = input.split("-");
    //     return new Date(Number(year), Number(month) - 1, Number(day));
    // }
    // function formatDateToYearMonthDayDateString(date) {
    //     const year = String(date.getFullYear());
    //     let month = String(date.getMonth() + 1);
    //     let day = String(date.getDate());
    //     if (month.length < 2) {
    //         month = String(month).padStart(2, "0");
    //     }
    //     if (day.length < 2) {
    //         day = String(day).padStart(2, "0");
    //     }
    //     return [year, month, day].join("-");
    // }
    // function formatDate(date) {
    //     return formatDateToYearMonthDayDateString(date);
    // }
    // function nodeContainsDescendant(rootNode, descendant) {
    //     if (rootNode === descendant) {
    //         return true;
    //     }
    //     let parent = descendant.parentNode;
    //     while (parent != null) {
    //         if (parent === rootNode) {
    //             return true;
    //         }
    //         parent = parent.parentNode;
    //     }
    //     return false;
    // }
    // function isNodeWithinPopover(node) {
    //     return datePickerRef?.current
    //         ? nodeContainsDescendant(datePickerRef.current, node)
    //         : false;
    // }
    // function handleStartInputValueChange(value) {
    //     setInputValues((prevState) => {
    //         return { ...prevState, since: value };
    //     });
    //     if (isValidDate(value)) {
    //         const newSince = parseYearMonthDayDateString(value);
    //         setActiveDateRange((prevState) => {
    //             const newPeriod =
    //                 prevState.period && newSince <= prevState.period.until
    //                     ? { since: newSince, until: prevState.period.until }
    //                     : { since: newSince, until: newSince };
    //             return {
    //                 ...prevState,
    //                 period: newPeriod,
    //             };
    //         });
    //     }
    // }
    //
    // function handleEndInputValueChange(value) {
    //     setInputValues((prevState) => ({ ...prevState, until: value }));
    //     if (isValidDate(value)) {
    //         const newUntil = parseYearMonthDayDateString(value);
    //         setActiveDateRange((prevState) => {
    //             const newPeriod =
    //                 prevState.period && newUntil >= prevState.period.since
    //                     ? { since: prevState.period.since, until: newUntil }
    //                     : { since: newUntil, until: newUntil };
    //             return {
    //                 ...prevState,
    //                 period: newPeriod,
    //             };
    //         });
    //     }
    // }
    //
    // function handleInputBlur({ relatedTarget }) {
    //     const isRelatedTargetWithinPopover =
    //         relatedTarget != null && isNodeWithinPopover(relatedTarget);
    //     // If focus moves from the TextField to the Popover
    //     // we don't want to close the popover
    //
    //     // if (isRelatedTargetWithinPopover) {
    //     //     return;
    //     // }
    //     // setPopoverActive(false);
    // }

    function handleMonthChange(month, year) {
        setDate({ month, year });
    }

    function handleCalendarChange({ start, end }) {
        const newDateRange = ranges.find((range) => {
            return (
                range.period.since.valueOf() === start.valueOf() &&
                range.period.until.valueOf() === end.valueOf()
            );
        }) || {
            alias: "custom",
            title: "Custom",
            period: {
                since: start,
                until: end,
            },
        };
        setActiveDateRange(newDateRange);
    }

    function apply() {
        setPopoverActive(false);
        onChange({startDate: activeDateRange.period.since, endDate: activeDateRange.period.until})
    }

    function cancel() {
        setPopoverActive(false);
    }

    useEffect(() => {
        if (activeDateRange) {
            // setInputValues({
            //     since: formatDate(activeDateRange.period.since),
            //     until: formatDate(activeDateRange.period.until),
            // });
            function monthDiff(referenceDate, newDate) {
                return (
                    newDate.month -
                    referenceDate.month +
                    12 * (referenceDate.year - newDate.year)
                );
            }
            const monthDifference = monthDiff(
                { year, month },
                {
                    year: activeDateRange.period.until.getFullYear(),
                    month: activeDateRange.period.until.getMonth(),
                }
            );
            if (monthDifference > 1 || monthDifference < 0) {
                setDate({
                    month: activeDateRange.period.until.getMonth(),
                    year: activeDateRange.period.until.getFullYear(),
                });
            }
        }
    }, [activeDateRange]);

    const buttonValue =
        activeDateRange.title === "Custom"
            ? activeDateRange.period.since.toDateString() +
            " - " +
            activeDateRange.period.until.toDateString()
            : activeDateRange.title;

    return (
        <Popover
            active={popoverActive}
            autofocusTarget="none"
            preferredAlignment="left"
            preferredPosition="below"
            fluidContent
            sectioned={false}
            fullHeight
            activator={
                <Button icon={CalendarIcon} variant={"primary"} onClick={() => setPopoverActive(!popoverActive)}>
                    {buttonValue}
                </Button>
            }
            onClose={() => setPopoverActive(false)}
        >
            <Popover.Pane>
                <InlineGrid
                    columns={{
                        xs: "1fr",
                        mdDown: "1fr",
                        md: "max-content max-content",
                    }}
                    gap={0}
                    ref={datePickerRef}
                >
                    <Box
                        maxWidth={mdDown ? "516px" : "212px"}
                        width={mdDown ? "100%" : "212px"}
                        padding={{ xs: "400", md: "0" }}
                        paddingBlockEnd={{ xs: "100", md: "0" }}
                    >
                        {mdDown ? (
                            <Select
                                label="dateRangeLabel"
                                labelHidden
                                onChange={(value) => {
                                    const result = ranges.find(
                                        ({ title, alias }) => title === value || alias === value
                                    );
                                    setActiveDateRange(result);
                                }}
                                value={activeDateRange?.title || activeDateRange?.alias || ""}
                                options={ranges.map(({ alias, title }) => title || alias)}
                            />
                        ) : (
                            <OptionList
                                options={ranges.map((range) => ({
                                    value: range.alias,
                                    label: range.title,
                                }))}
                                selected={activeDateRange.alias}
                                onChange={(value) => {
                                    setActiveDateRange(
                                        ranges.find((range) => range.alias === value[0])
                                    );
                                }}
                            />
                        )}
                    </Box>
                    <Box padding={{ xs: "400" }} maxWidth={mdDown ? "320px" : "550px"}>
                        <Card padding={smDown ? "0" : "400"}>
                            <DatePicker
                                month={month}
                                year={year}
                                selected={{
                                    start: activeDateRange.period.since,
                                    end: activeDateRange.period.until,
                                }}
                                disableDatesAfter={today}
                                // disableDatesBefore={currentDate}
                                onMonthChange={handleMonthChange}
                                onChange={handleCalendarChange}
                                multiMonth={shouldShowMultiMonth}
                                allowRange
                            />
                        </Card>
                    </Box>
                </InlineGrid>
                {/*</Scrollable>*/}
            </Popover.Pane>
            <Divider />
            <Popover.Pane fixed>
                <Popover.Section>
                    <Box padding={"200"}>
                        <InlineStack align={"end"}>
                            <ButtonGroup>
                                <Button onClick={cancel}>Cancel</Button>
                                <Button variant={"primary"} onClick={apply}>Apply</Button>
                            </ButtonGroup>
                        </InlineStack>
                    </Box>
                </Popover.Section>
            </Popover.Pane>
        </Popover>
    )
}