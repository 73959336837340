import React, {Fragment, useCallback, useContext, useState} from 'react';
import {Button, ButtonGroup, Badge, TextField, BlockStack, Tooltip, InlineStack, Popover, Box, SkeletonBodyText, ActionList, Text, Icon, Divider,} from "@shopify/polaris";
import {
    CheckIcon, DeleteIcon, DesktopIcon, DragHandleIcon, EditIcon, ExitIcon, FileIcon, LightbulbIcon, MenuHorizontalIcon, MobileIcon, PlusCircleIcon, ViewIcon, XIcon
} from "@shopify/polaris-icons";
import {useWindowSize} from "../CommonUse/CommonUse";
import {apiService, baseUrl, onKeyFire, webDomain} from "../../../../Utils/Constent";
import EllipsisText from "../../../Common/EllipsisText";
import Confirmation from "../../../Common/Confirmation";
import {ToastContext} from "../../../Common/ToastProvider";
import {useNavigate, useParams} from "react-router-dom";
import qs from "qs";
import {workSpaceDetailsAction} from "../../../../Redux/Action/Action";
import {useDispatch, useSelector} from "react-redux";

const initialDnDStatePage = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
}

const Header = ({headerProps}) => {
    const {
        blockArea,
        isLoading,
        formData,
        isMobileView,
        setIsMobileView,
        onBack,
        onSave,
        onPublish,
        loading,
        type,
        isEditName,
        setIsEditName,
        editName,
        setEditName,
        onSaveFromName,
        isDirty,
        isDirtyCustomize,
        onUseThisBlock,
        setBlockArea,
        checkChange,
        oldBlockAreas,
        setSelectedHeadPageIndex,
        selectedHeadPageIndex,
        isSaved,
        onDeletePage,
        pageTitle,
        setPageTitle,
        setSelectedPageIndex,
        setSelectedBlockIndex,
        setSelectedFieldType,
    } = headerProps;
    const { setMessage, setIsError, setActive } = useContext(ToastContext);
    const {workspaceId, id} = useParams();
    const navigate = useNavigate();

    const {width} = useWindowSize();
    const [popoverActive, setPopoverActive] = useState(false);
    const [popoverPage, setPopoverPage] = useState(false);
    const [dragAndDropPage, setDragAndDropPage] = useState(initialDnDStatePage);
    const [draggingPage, setDraggingPage] = useState(null);
    const [forLoading, setForLoading] = useState('');
    const [isDelete, setIsDelete] = useState(false);
    const dispatch = useDispatch();
    const workSpacesAll = useSelector((state) => state.workSpaceDetails);

    const handleChange = useCallback((event) => {
        setEditName(event)
        }, [isEditName],
    );

    const actionArray = [
        width > 475 && formData.formLayout != 3 ? {content: `Desktop`,icon: DesktopIcon , onAction : () => setIsMobileView(false)} : false,
        width > 475 && formData.formLayout != 3 ? {content: `Mobile`,icon: MobileIcon , onAction : () => setIsMobileView(true)} : false,
        {content: `${formData.publishStatus == 0 ? 'Publish' : "Unpublish"}`,icon: LightbulbIcon , onAction : () => onPublish(), loading: loading === 'publish',disabled: loading === 'publish' || blockArea[1]?.length <= 0},
        type !== 'thank-you' && {content: `Preview`,icon: ViewIcon, onAction : () => window.open(`${webDomain}/form/preview/${formData?.publishId||""}`, "_blank"), disabled: blockArea[1]?.length <= 0},
        {content: "Delete",icon : DeleteIcon, onAction: () => setIsDelete(true),destructive: true, }
    ]

    // drag and drop
    const onDragStartPage = (event, pageIndex) => {
        const initialPosition = Number(event.currentTarget.dataset.position);
        setDraggingPage(pageIndex);
        setDragAndDropPage({
            ...dragAndDropPage,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: Object.keys(blockArea)
        });
        event.dataTransfer.setData("text/plain", '');
    };

    const onDragOverPage = (event, pageIndex) => {
        event.preventDefault();
        let newList = [...dragAndDropPage.originalOrder];
        const draggedFrom = dragAndDropPage.draggedFrom;
        const draggedTo = pageIndex;
        const itemDragged = newList[draggedFrom];
        newList.splice(draggedFrom, 1);
        newList.splice(draggedTo, 0, itemDragged);
        setDragAndDropPage({
            ...dragAndDropPage,
            updatedOrder: newList,
            draggedTo: draggedTo
        });
    };

    const onDropPage = () => {
        setDraggingPage(null);
        const updatedBlockArea = {...blockArea};
        let hasUndefinedKey = false;
        for (const key of dragAndDropPage?.updatedOrder) {
            if (key === undefined) {
                hasUndefinedKey = true;
                break;
            }
        }
        if (!hasUndefinedKey) {
            let Obj = {}
            dragAndDropPage.updatedOrder.map((x, i) => {
                Obj[i + 1] = updatedBlockArea[x]
            })
            setBlockArea(Obj);
            checkChange(Obj, oldBlockAreas)
            setDragAndDropPage({
                ...dragAndDropPage,
                draggedFrom: null,
                draggedTo: null,
                isDragging: false
            });
        }
    };

    const onDragLeavePage = () => {
        setDragAndDropPage({
            ...dragAndDropPage,
            draggedTo: null
        });
    };

    const onGetDetail = (title, index) => {
        setPageTitle(title)
        setSelectedHeadPageIndex(index)
        setSelectedPageIndex(null)
        setSelectedBlockIndex(null)
        setSelectedFieldType(null)
    }

    const onPageAdd = () => {
        let clone = {...blockArea};
        setPageTitle(`Page - ${Object.keys(clone).length + 1|| 0}`)
        onUseThisBlock('', "addPage", '', '')
    }

    const updateWorkSpaceAll = (sign) => {
        const updatedWorkSpaces = (workSpacesAll || []).map(x => {
            if (x.id == workspaceId) {
                return {
                    ...x,
                    formCount: x?.formCount + (sign === '+' ? 1 : sign === '-' ? -1 : 0),
                };
            }
            return x;
        });
        dispatch(workSpaceDetailsAction(updatedWorkSpaces));
    };

    const onFormDelete = async () => {
        setForLoading('delete')
        const response = await apiService.deleteForm(id);
        if (response.success === true) {
            setForLoading('')
            onCloseFormDeleteModal()
            updateWorkSpaceAll('-')
            setMessage("Form Moved to Trash 🚮");
            navigate({
                pathname: `${baseUrl}/workspace/${workspaceId}`,
                search: qs.stringify({workSpaceTab: 0}),
            });
            setActive(true);
            setIsError(false)
        } else {
            setForLoading('')
            setActive(true);
            setMessage(response?.error?.message);
            setIsError(true);
        }
    }

    const onCloseFormDeleteModal = () => {
        setIsDelete(false)
    }

    return (
        <Fragment>
            {
                isDelete &&
                    <Confirmation
                        title={"Delete  Form"} open={isDelete}
                        onConfirm={onFormDelete}
                        isLoading={forLoading === 'delete'}
                        onClose={forLoading === 'delete' ? null : onCloseFormDeleteModal}
                        message={'Are you sure you want to delete the form?'}
                    />
            }

            <div className={"header"}>
                {
                    isLoading ? <SkeletonBodyText lines={2}/> :
                        <Fragment>
                            <div className={"left-btn"}>
                                <Tooltip content={"Back"} dismissOnMouseOut>
                                    <Button variant={"monochromePlain"} onClick={onBack} size={"large"}
                                            icon={ExitIcon}/>
                                </Tooltip>
                            </div>

                            <Text variant={"headingLg"}><EllipsisText text={formData?.name || ''} maxWidth={200}/></Text>

                            <Box paddingBlockStart={"150"} paddingInlineStart={"300"}>
                                <Popover
                                    active={isEditName}
                                    activator={<Tooltip content={"Edit name"} active={isEditName ? false : ""}><Button
                                        variant={"monochromePlain"} icon={EditIcon} disabled={loading === 'formName'}
                                        onClick={() => setIsEditName(!isEditName)}/></Tooltip>}
                                    autofocusTarget="first-node" ariaHaspopup={false}
                                    sectioned={isEditName}
                                    onClose={() => setIsEditName(!isEditName)}
                                >
                                    {
                                        isEditName && <BlockStack gap={"200"}>
                                            <Box as={"span"} onKeyPress={(e) => onKeyFire(e, onSaveFromName)}>
                                                <TextField
                                                    value={editName}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Edit name" autoFocus autoComplete={"off"}
                                                    error={editName?.trim() === '' ? "Name is required." : false}
                                                />
                                            </Box>
                                            <ButtonGroup>
                                                <Button variant={"primary"} loading={loading === 'formName'}
                                                        icon={CheckIcon} onClick={onSaveFromName}/>
                                                <Button icon={XIcon} onClick={() => setIsEditName(false)}/>
                                            </ButtonGroup>
                                        </BlockStack>
                                    }
                                </Popover>
                            </Box>

                            <Box paddingBlockStart={"025"} paddingInlineStart={"300"}>
                                {
                                    width <= 475 ? <Tooltip content={formData.publishStatus == 0 ?
                                            <Badge tone={"critical"}>Unpublish</Badge> :
                                            <Badge tone={"info"}>Publish</Badge>}><Box width={"12px"} minHeight={"12px"}
                                                                                       background={formData.publishStatus == 0 ? "bg-fill-critical-secondary" : "bg-fill-info-secondary"}
                                                                                       borderRadius={"full"}/></Tooltip>
                                        : formData.publishStatus == 0 ? <Badge tone={"critical"}>Unpublish</Badge> :
                                            <Badge tone={"info"}>Publish</Badge>
                                }
                            </Box>

                            {
                                type === 'form' && formData.formLayout == 2 ?
                                <div className={"page-action"}>
                                    <Popover
                                        active={popoverPage}
                                        activator={
                                            <Button variant={"tertiary"}  size={"large"} icon={FileIcon} onClick={()=> setPopoverPage(!popoverPage)} disclosure={popoverPage ? "up" : "down"}>
                                                {width <= 425 ? pageTitle.replace('Page -', '') : pageTitle}
                                            </Button>
                                        }
                                        autofocusTarget="first-node"
                                        onClose={() => setPopoverPage(!popoverPage)}
                                    >
                                        <Box minWidth={width <= 300 ? "280px" :"300px"}>
                                            {
                                                isLoading ? <SkeletonBodyText lines={8}/> :
                                                    <div className={`active-`}>
                                                        {Object.keys(blockArea).map((x, pageIndex) => {
                                                            return (
                                                                <Box paddingInline={"200"}
                                                                     paddingBlockStart={pageIndex === 0 ? "200" : ''}
                                                                     key={pageIndex}>
                                                                    <div className={`chev-main `}>
                                                                        <div className="parent-blk">
                                                                            <div
                                                                                className={`page-blocker ${draggingPage === pageIndex ? 'dragging-page' : ''}`}
                                                                                data-position={pageIndex} key={x}
                                                                                draggable={true}
                                                                                onDragStart={(e) => onDragStartPage(e, pageIndex)}
                                                                                    onDragOver={(e) => onDragOverPage(e, pageIndex)}
                                                                                    onDrop={() => onDropPage()} onDragLeave={() => onDragLeavePage()}>
                                                                                    <div className={`card-pg ${selectedHeadPageIndex === pageIndex ? "active":""}`} onClick={() => onGetDetail(`Page - ${x}`, pageIndex)}>
                                                                                        <button className="drag-btn" onClick={e => e.stopPropagation()}><Icon source={DragHandleIcon}/></button>
                                                                                        <span className="span-mid"><Icon source={FileIcon}/><span className="title-p">Page - {x}</span></span>
                                                                                        <Tooltip content={"Delete Page"} dismissOnMouseOut><Button onClick={(e) => onDeletePage(e, x)} disabled={Object.keys(blockArea).length <= 1 && x <= 1} icon={DeleteIcon} variant="monochromePlain"/></Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Box>
                                                                )
                                                            })}
                                                            <Divider/>
                                                            <Box padding={"200"}>
                                                                <button className="add-block" onClick={onPageAdd}><Icon source={PlusCircleIcon}/> Add Page</button>
                                                            </Box>
                                                        </div>
                                                }
                                        </Box>
                                    </Popover>
                                </div> : null
                            }

                            {(type === 'form' || type === 'customize' || type === 'thank-you') &&
                                <div className={"resp-btn-area"}>
                                    {width <= 767 ?
                                        <Box paddingBlockStart={"150"}>
                                            <Popover
                                                active={popoverActive}
                                                activator={<Tooltip content={"Actions"}><Button
                                                    variant={"monochromePlain"} icon={MenuHorizontalIcon}
                                                    onClick={() => setPopoverActive((popoverActive) => !popoverActive)}/></Tooltip>}
                                                autofocusTarget="first-node"
                                                onClose={() => setPopoverActive((popoverActive) => !popoverActive)}
                                            >
                                                <ActionList actionRole="menuitem" items={actionArray}/>
                                            </Popover>
                                        </Box>
                                        :
                                        <InlineStack gap={"300"} blockAlign={"center"}>

                                            {
                                                formData.formLayout != 3 &&
                                                <ButtonGroup variant={"segmented"}>
                                                    <Tooltip dismissOnMouseOut content="Desktop">
                                                        <Button size={"large"} pressed={!isMobileView}
                                                                onClick={() => setIsMobileView(false)} icon={DesktopIcon}/>
                                                    </Tooltip>
                                                    <Tooltip dismissOnMouseOut content="Mobile">
                                                        <Button size={"large"} pressed={isMobileView}
                                                                onClick={() => setIsMobileView(true)} icon={MobileIcon}/>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            }

                                            {
                                                type !== 'thank-you' &&
                                                <Tooltip dismissOnMouseOut content={"Preview"} preferredPosition={"above"}>
                                                    <Button size={"large"} icon={ViewIcon}
                                                            onClick={() => window.open(`${webDomain}/form/preview/${formData?.publishId || ""}`, "_blank")}
                                                            disabled={(!((type === 'form' || type === 'customize') && isSaved))}/>
                                                </Tooltip>
                                            }

                                            <Tooltip dismissOnMouseOut content={"Delete Form"} preferredPosition={"above"}>
                                                <Button variant={"primary"} tone={"critical"} onClick={() => setIsDelete(true)}>Delete</Button>
                                            </Tooltip>

                                            <Tooltip dismissOnMouseOut content={"Publish"} preferredPosition={"above"}>
                                                <Button disabled={blockArea[1]?.length <= 1 && !isSaved} size={"large"}
                                                        variant={"primary"} loading={loading === 'publish'}
                                                        onClick={onPublish}>{formData.publishStatus == 0 ? 'Publish' : "Unpublish"}</Button>
                                            </Tooltip>
                                        </InlineStack>
                                    }

                                    <Box paddingInlineStart={"300"}>
                                        <Tooltip dismissOnMouseOut
                                                 content={`Save ${type === 'form' ? 'Form' : (type === 'thank-you' || type === 'customize') ? 'Design' : 'Form'}`}
                                                 preferredPosition={"above"}>
                                            <Button size={"large"} variant={"primary"} loading={loading === 'save'}
                                                    onClick={()=> onSave('on')}
                                                    disabled={(((!isDirty) && type === 'form') || ((!isDirtyCustomize) && (type === 'customize' || type === 'thank-you')))}>
                                                Save
                                            </Button>
                                        </Tooltip>
                                    </Box>
                                </div>
                            }
                        </Fragment>
                }
            </div>
        </Fragment>
    );
};

export default Header;