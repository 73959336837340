import React, {Fragment, useEffect, useState} from 'react';
import {Box, Divider, Text, BlockStack, TextField, Tooltip, Button} from "@shopify/polaris";
import ReactQuill from "react-quill";
import {ChevronDownIcon, ChevronUpIcon} from "@shopify/polaris-icons";
import {isContentEmpty, updateStyles, useWindowSize} from "../CommonUse/CommonUse";
import {Copyright} from "../../../../Utils/Constent";

const ThankYou = ({commonPropsThankYou}) => {
    const {formData, handleChangeCustomize, objColors, formError, isMobileView, blockArea} = commonPropsThankYou;
    const {thankYouTitle, thankYouDescription, thankYouBtnText, thankYouLink} = formData;
    const [isToggle, setIsToggle] = useState(false);
    const {width} = useWindowSize();

    useEffect(() => {
        updateStyles(['.view'], '--form-font-apply', formData?.style?.fontFamily);
    }, [formData?.style?.fontFamily, blockArea]);

    return (
        <Fragment>
            <div className={`left-bar ${(width <= 767) ? 'fixed' : ''} ${isToggle}`}>
                {
                    (width <= 767) && <div className={"sticky"}>
                        <Box paddingBlock={"300"} paddingBlockEnd={'200'} borderBlockEndWidth={"025"} borderColor={"border-secondary"} borderStyle={"solid"}>
                            <div onClick={() => setIsToggle(!isToggle)} className={"cursor-pointer"}>
                                <BlockStack  align={"center"} inlineAlign={"center"}>
                                    <Tooltip content={`${isToggle ? 'Close' : 'Open'} Customize`} dismissOnMouseOut>
                                        <Button variant={"monochromePlain"} icon={isToggle ? ChevronDownIcon : ChevronUpIcon}/>
                                    </Tooltip>
                                    <Text variant={"headingMd"}>Customize</Text>
                                </BlockStack>
                            </div>
                        </Box>
                    </div>
                }

                <Box paddingBlock={"300"} paddingInline={"400"}>
                    <BlockStack gap={"200"}>

                        <TextField
                            label="Title"
                            placeholder={"Type a title"}
                            value={thankYouTitle}
                            onChange={(value) => handleChangeCustomize('thankYouTitle', value)}
                            autoComplete="off"
                            error={formError.thankYouTitle}
                        />

                        <BlockStack>
                            <div className="Polaris-Labelled__LabelWrapper">
                                <div className="Polaris-Label">
                                    <label className={`Polaris-Label__Text`}>Description</label>
                                </div>
                            </div>
                            <ReactQuill
                                placeholder={"Enter description here"}
                                className={`"ql-disabled"`}
                                value={thankYouDescription}
                                modules={{
                                    toolbar: {container: [["bold","underline", "italic", 'link']]},
                                    clipboard: { matchVisual: false }
                                }}
                                onChange={(newValue, delta, source) => {
                                    if (source === 'user') {
                                        handleChangeCustomize('thankYouDescription', newValue)
                                    }
                                }}
                            />
                        </BlockStack>

                        {/*<TextField*/}
                        {/*    label="Button Text"*/}
                        {/*    placeholder={"Type a text"}*/}
                        {/*    value={thankYouBtnText}*/}
                        {/*    onChange={(value) => handleChangeCustomize('thankYouBtnText', value)}*/}
                        {/*    autoComplete="off"*/}
                        {/*    error={formError.thankYouBtnText}*/}
                        {/*/>*/}

                        {/*<TextField*/}
                        {/*    label="Button Link"*/}
                        {/*    placeholder={"form.webcontrive.com"}*/}
                        {/*    prefix={<Icon source={LinkIcon}/>}*/}
                        {/*    value={thankYouLink}*/}
                        {/*    onChange={(value) => handleChangeCustomize('thankYouLink', value)}*/}
                        {/*    autoComplete="off"*/}
                        {/*    error={formError.thankYouLink}*/}
                        {/*/>*/}

                    </BlockStack>
                </Box>

                <Divider/>
            </div>

            <div className={"middle-bar"} style={{ backgroundColor: formData?.style?.globalBg}}>
                <div className={`view ${isMobileView ? "mobile-view" : "desk-view"}`} style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
                    <BlockStack gap={"600"} align={"center"}>
                        <h2 className="Polaris-Text--root Polaris-Text--heading3xl Polaris-Text--center">{thankYouTitle}</h2>

                        {isContentEmpty(thankYouDescription) ? null :
                            <span className="Polaris-Text--root Polaris-Text--bodyLg Polaris-Text--center" dangerouslySetInnerHTML={{__html: thankYouDescription}}/>
                        }

                        {/*<div className={"btn-submit"} style={{justifyContent: `center`}}>*/}

                        {/*    {thankYouBtnText?.trim() !== '' ?  <button className="Polaris-Button Polaris-Button--sizeLarge" type="button" style={objColors}><span*/}
                        {/*        className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--medium"*/}
                        {/*    >{thankYouBtnText}</span></button>: ""}*/}
                        {/*</div>*/}
                    </BlockStack>
                </div>
                <footer><Text>{Copyright}</Text></footer>
            </div>

            {
                (width > 1200) &&
                <div className={"right-bar"}>
                    <Box padding={"400"}>
                        <BlockStack gap={"200"}>
                            <Text variant={"headingLg"}>Thank You Page</Text>
                            <Text variant={"p"} tone={"subdued"}>Customize your 'Thank You' message.</Text>
                        </BlockStack>
                    </Box>
                    <Divider/>
                </div>
            }

        </Fragment>
    );
};

export default ThankYou;