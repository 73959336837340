import React, {Fragment} from 'react';
import {BlockStack, Box, Button, InlineStack, Modal, Text, TextField} from "@shopify/polaris";
import {Icons} from "../../../Utils/Icons";
import {HideIcon, ViewIcon} from "@shopify/polaris-icons";

const DeleteAccount = ({
                           modalType,
                           onCancelModal,
                           profileDetails,
                           loading,
                           authPassword,
                           authPasswordError,
                           isShowPaas,
                           onChangeAuth,
                           setIsShowPaas,
                           handleGoogle,
                           onModalHit
                       }) => {
    return (
        <Fragment>
            <Modal
                open={modalType === 'DeleteAccount'}
                onClose={onCancelModal}
                title={<InlineStack gap={"200"} as={"span"} blockAlign={"center"}>{Icons.danger}<Text as={"span"}>Delete Account</Text></InlineStack>}
                primaryAction={{
                    content: 'Yes, I want to delete',
                    onAction:  () => profileDetails.loginType == 1 ? handleGoogle('DelAcc') :  onModalHit('DelAcc', 'DelAcc'),
                    loading: loading === 'DelAcc',
                    destructive : true
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: onCancelModal,
                    },
                ]}
            >
                <Modal.Section>
                    <BlockStack gap={"400"}>
                        <Text tone={"subdued"}>Are you sure you want to delete?</Text>
                        <Text tone={"subdued"}>Before you proceed with deleting your account, please be aware that this action is irreversible. All data, including forms, submissions, and workspaces, will be permanently deleted.</Text>

                        { profileDetails.loginType == 0 ?
                            <TextField
                                label="Password"
                                placeholder={"Enter password"}
                                value={authPassword} type={isShowPaas ? "text" : "password"}
                                error={authPasswordError}
                                onChange={(value) => onChangeAuth(value)}
                                suffix={
                                    <Box paddingBlockStart={"100"}><Button icon={!isShowPaas ? ViewIcon : HideIcon} variant={"plain"} onClick={()=> setIsShowPaas(!isShowPaas)}/></Box>
                                }
                            /> : null}
                    </BlockStack>
                </Modal.Section>
            </Modal>
        </Fragment>
    );
};

export default DeleteAccount;