import React, {Fragment, useState} from 'react';
import {Icon, Tooltip, Text, Divider} from "@shopify/polaris";
import {AdjustIcon, ChartVerticalFilledIcon, PaintBrushRoundIcon, ShareIcon, ShippingLabelIcon, SmileyHappyIcon, WrenchIcon, XIcon} from "@shopify/polaris-icons";
import {useWindowSize} from "../CommonUse/CommonUse";
import {Icons} from "../../../../Utils/Icons";

const MiniBar = ({commonPropsMiniBar}) => {
    const {
        handleTabChange,
        type,
        setIsShareModal,
        isShareModal,
        isHtmlModal,
        setIsHtmlModal,
        formData,
        isLoading,
        isDirty,
        isDirtyCustomize,
        onSave,
    } = commonPropsMiniBar;

    const [isToggle, setIsToggle] = useState(false);
    const {width} = useWindowSize();

    const miniBar = [
        {
            icon: WrenchIcon,
            selected: type === 'form',
            routName: 'form',
            toolTip: "Form"
        },
        isLoading || formData.formLayout == 3 ? null : {
            icon: PaintBrushRoundIcon,
            selected: type === 'customize',
            routName: 'customize',
            toolTip: "Form Settings"
        },
        {
            icon: SmileyHappyIcon,
            selected: type === 'thank-you',
            routName: 'thank-you',
            toolTip: "Thank You"
        },
        {
            icon: AdjustIcon,
            selected: type === 'integrate',
            routName: 'integrate',
            toolTip: "Integration"
        },
        {
            icon: ChartVerticalFilledIcon,
            selected: type === 'submission',
            routName: 'submission',
            toolTip: "Submission"
        },
        {
            icon: ShareIcon,
            selected: isShareModal,
            routName: 'modal-share',
            toolTip: "Share Link",
            disabled: formData?.publishStatus == '0' || isLoading
        },
        formData.formLayout == 3 ? {
            icon: ShippingLabelIcon,
            selected: isHtmlModal,
            routName: 'modal-html',
            toolTip: "HTML",
            // disabled: isDirty
        } : null,
    ];

    const onNavigate = async (routName) => {
        if (routName === 'modal-share') {
            setIsShareModal(true)
        } else if (routName === 'modal-html') {
            setIsHtmlModal(true)
            if(isDirty || isDirtyCustomize){
                await onSave()
            }
        } else {
            handleTabChange(routName)
            if(isDirty || isDirtyCustomize){
                await onSave()
            }
        }
        setIsToggle(false)
    }

    return (
        <Fragment>
            {(isToggle && width <= 475) && <div className={"Polaris-Backdrop Polaris-Backdrop--belowNavigation"} onClick={() => setIsToggle(false)}/>}
            {(width <= 475) && <Fragment>
                <button type="button" className="Polaris-TopBar__NavigationIcon Minibar-toggle" aria-label="Toggle menu" onClick={() => setIsToggle(!isToggle)}>
                    <div className="Polaris-TopBar__IconWrapper"><span className="Polaris-Icon">{Icons.menuBar}</span></div>
                </button>
            </Fragment>}

            <div className={`mini-bar ${isToggle} ${width <= 475 ? "max-bar":''}`}>
                {
                    (miniBar || []).filter(y => y !== null).map((x,i) => {
                        return (
                            <Fragment key={i}>
                                {
                                    width > 475 ?
                                    <Tooltip content={x.toolTip} dismissOnMouseOut>
                                        <button disabled={x?.disabled} onClick={x?.disabled ? null : () => onNavigate(x.routName)} className={`${x.selected ? "active":""}`}><Icon source={x.icon} tone="base"/></button>
                                    </Tooltip> :
                                    <Fragment>
                                        <span className={`box ${x.selected ? "active":""} ${x?.disabled? true:''}`} onClick={x?.disabled ? null : () => onNavigate(x.routName)}>
                                            <button disabled={x?.disabled} onClick={x?.disabled ? null : () => onNavigate(x.routName)} className={`${x.selected ? "active":""}`}><Icon source={x.icon} tone="base"/></button>
                                            <Text fontWeight={"medium"}>{x.toolTip}</Text>
                                        </span>
                                        <Divider/>
                                    </Fragment>
                                }
                            </Fragment>
                        )
                    })
                }
            </div>
                {
                    (width <= 475 && isToggle ) &&<button type="button" className="Polaris-Frame__NavigationDismiss MiniBar_Dismiss" aria-hidden="false"
                            aria-label="Close navigation" tabIndex="0"><span className="Polaris-Icon"><Icon source={XIcon} tone="base"/></span>
                    </button>
                }
        </Fragment>
    );
};

export default MiniBar;