import React, {Fragment} from 'react';
import {Card, Page} from "@shopify/polaris";

const WhatSNew = () => {
    return (
        <Fragment>
            <Page title="What’s New">
                <Card>
                    <div className={"videoWidget iframeEmbed whats"}>
                        <iframe src="https://webform.quickhunt.app/widget/ideas?widget=172"></iframe>
                    </div>
                </Card>
            </Page>
        </Fragment>
    );
};

export default WhatSNew;