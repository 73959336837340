import React, {Fragment} from 'react';
import {BlockStack, Text, Button, Box, InlineStack, Thumbnail} from "@shopify/polaris";
import {apiService} from "../../Utils/Constent";
import webFormLogo from "../../Img/logoBlackWF.png";
import zapierLogo from "../../Img/zapier.webp";
import {useSelector} from "react-redux";

const ZapierAuthorize = () => {
    const planDetails = useSelector((state) => state.planDetails);

    const urlParams = new URLSearchParams(window.location.search)
    const client_id = urlParams.get("client_id")
    const state = urlParams.get("state")
    const redirect_uri = urlParams.get("redirect_uri")
    const response_type = urlParams.get("response_type")

    const onAccept = async () => {
        const payload = {
            client_id, state, redirect_uri, response_type
        }
        const response = await apiService.acceptZapier(payload)
        window.open(response.data, "_self");
    }

    const onNavigate = () => {
        window.open('https://zapier.com/dashboard/auth/oauth/return', "_self")
    }

    return (
        <Fragment>
            <div className="main-page">
                <Box maxWidth={"500px"}>
                    <BlockStack gap={"1000"}>
                        <InlineStack align={"center"} blockAlign={"center"} gap={"600"}>
                            <Thumbnail source={webFormLogo} size="medium" alt="logo-webform"/>
                            <InlineStack gap={"100"}>{Array.from(Array(3)).map((_,r) => <Box width={"8px"} minHeight={"8px"} borderRadius={"full"} background={"bg-fill-secondary-active"}/>)}</InlineStack>
                            <Thumbnail source={zapierLogo} size="medium" alt="logo-webform"/>
                        </InlineStack>

                        <Text as={"h2"} variant={"headingXl"}>To proceed, please grant Zapier permission to access the
                            following information from your Webform account:</Text>

                        <BlockStack gap={"600"}>
                            <BlockStack gap={"100"}>
                                <Text variant={"headingLg"}>Account Information</Text>
                                <Text>Allows Zapier to view your name, email, and avatar.</Text>
                            </BlockStack>

                            <BlockStack gap={"100"}>
                                <Text variant={"headingLg"}>Forms</Text>
                                <Text>Enables Zapier to retrieve a list of your existing forms.</Text>
                            </BlockStack>

                            <BlockStack gap={"100"}>
                                <Text variant={"headingLg"}>Submissions</Text>
                                <Text>Permits Zapier to access a list of submissions for your forms.</Text>
                            </BlockStack>

                            <BlockStack gap={"100"}>
                                <Text variant={"headingLg"}>Webhooks</Text>
                                <Text>Allows Zapier to read and manage webhooks</Text>
                            </BlockStack>
                        </BlockStack>

                        <BlockStack gap={"300"}>
                            <Button variant={"primary"} size={"large"} onClick={onAccept}>Accept</Button>
                            <Button size={"large"} onClick={onNavigate}>Cancel</Button>
                        </BlockStack>
                    </BlockStack>
                </Box>
            </div>
        </Fragment>
);
};

export default ZapierAuthorize;